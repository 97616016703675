import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({

    Title: {
        fontSize: 35,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 20,
    },

    container: {
        flex: 1,
        zoom: 0.75,
        backgroundColor: "#0F0F0F"
    },

    menu: {
        justifyContent: 'left',
        alingnItens: 'center',
        height: 975,
        width: 300,
        borderTopRightRadius: 70,
        borderBottomRightRadius: 70,
        borderColor: '#29D8FF',
        borderStyle: 'solid',
        borderWidth: 2,
        borderLeftColor: '#055669'
    },
    
    header: {
        height: 115,
        backgroundColor: "#FFFFFF15",
      },

    titleMenu: {
        fontSize: 35,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 10,
        marginBottom: 25,
        marginLeft: 60,
    },

    buttomMenu: {
        alignItems: 'left',
        alignContent: 'center',
        position: 'absolute',
        marginTop: 30,
        marginLeft: 40,
    },

    MenuModulos: {
        fontSize: 20,
        textAlign: 'center',
        color: '#fff',
    },

    MenuDescription: {
        fontSize: 15,
        textAlign: 'center',
        color: '#fff',
    },

    divReturn: {
        backgroundColor: '#fff',
        alignContent: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        height: 150,
        width: 1000,
        margin: 30,
        borderRadius: 30,

    },

    textReturnTitle: {
        textAlign: 'left',
        fontSize: 25,
        marginTop: 10,
        marginLeft: 25,
        fontWeight: 'bold',
    },

    textReturnEmail: {
        textAlign: 'left',
        fontSize: 15,
        marginTop: 30,
        marginLeft: 25,
    },

    textReturnEmail2: {
        textAlign: 'left',
        fontSize: 15,
        marginTop: 10,
        marginLeft: 25,
        marginBottom: 10
    },

    textReturnGrupo: {
        textAlign: 'left',
        fontSize: 15,
        marginTop: 5,
        marginLeft: 25,
    },

    img: {
        width: 740,
        height: 985.5,
        margin: 30,
    },
    divImg: {
        alingnItens: 'center',
        alignContent: 'center',
        backgroundColor: 'transparent',
        marginLeft: 30,
        marginRight: 30,
    },

    buttonView: {
        width: 200,
        height: 50,
        backgroundColor: '#FA0437',
        borderRadius: 50,
        justifyContent: 'center',
        margin: 20
    },

    buttonView1: {
        width: 200,
        height: 50,
        backgroundColor: '#0031E5',
        borderRadius: 20,
        justifyContent: 'center',
        margin: 20
    },

    buttonView2: {
        width: 240,
        height: 50,
        backgroundColor: '#0031E5',
        borderRadius: 20,
        justifyContent: 'center',
        margin: 20
    },

    buttonView3: {
        width: 200,
        height: 50,
        backgroundColor: '#0031E5',
        borderRadius: 20,
        justifyContent: 'center',
        margin: 20
    },

    textView: {
        textAlign: 'center',
        fontSize: 15,
        color: '#fff',
    },

    buttonOk: {
        width: 200,
        height: 50,
        backgroundColor: '#00EB73',
        justifyContent: 'center',
        borderRadius: 30,
    },

    buttonProblema: {
        width: 200,
        height: 50,
        backgroundColor: '#EB0101',
        justifyContent: 'center',
        marginRight: 30,
        borderRadius: 30,
    },

    buttonProblema2: {
        width: 200,
        height: 50,
        backgroundColor: '#EB0101',
        justifyContent: 'center',
        borderRadius: 30,
    },


    viewReprovar: {
        justifyContent: 'center',
        alignSelf: 'center', 
        alignItems: 'center', 
        alignContent: 'center', 
        marginBottom: 100
    },

    input: {
        alignItems: 'center', 
        width: 600, 
        marginTop: 25, 
        padding: 25,
        height: 150, 
        marginBottom: 10, 
        textAlign: 'justify',
        borderWidth: 1, 
        borderColor: "#000000", 
        color: "#000000", 
        backgroundColor: '#fff',
        borderRadius: 30,
    },

    botaoAnexo: {
        backgroundColor: "#C4CCCA", 
        width: 220, 
        height: 50, 
        justifyContent: 'center', 
        borderRadius: 10, 
        margin: 30, 
        borderColor: "#000", 
        borderWidth: 1, 
        borderStyle: "dashed"
    }, 

    textAnexo: {
        color: "#000", 
        textAlign: "center", 
        fontSize: 17, 
        margin: 15
    },

    viewUpload: {
        alignContent: "center", 
        alignItems: "center", 
        justifyContent: "center",
        flexDirection: "row"
    },

    nomeImg: {
        color: "#FFF", 
        fontSize: 20, 
        textAlign: "center", 
        marginLeft: 20, 
        marginRight: 20, 
        marginTop: -60
    },

    botaoEliminar: {
        backgroundColor: "#E60130", 
        width: 30, 
        height: 30,  
        borderRadius: 50, 
        marginTop: -60
    }, 

    textEliminar: {
        color: "#FFF", 
        fontSize: 15, 
        textAlign: "center",
        marginTop: 5
    },

    botaoEnv: {
        width: 200,
        height: 50,
        backgroundColor: '#FF6553',
        justifyContent: 'center',
        borderRadius: 30,
        marginTop: 20
    }
});

export default styles