import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore} from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';
import styles from './style';


export default function HomeTareaG12({ navigation }) {

    const [retorno1, setRetorno1] = useState([]);
    const [retorno12, setRetorno12] = useState([]);
    const [retorno13, setRetorno13] = useState([]);

    useEffect(() => {
        getResumos1();
        getResumos2();
        getResumos3();
    }, [])

    useFocusEffect(
        React.useCallback(() => {
            getResumos1();
            getResumos2();
            getResumos3();
        }, [])
      );

    const getResumos1 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Análisis Facial'), where("grupo", "==", "Grupo12"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno1(array)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Cefalometría'), where("grupo", "==", "Grupo12"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno12(array)
    }

    const getResumos3 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Cefalometría (pdf webceph)'), where("grupo", "==", "Grupo12"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                url: doc.data().url,
                form: doc.data().nomeForm,
                status: doc.data().status,
                nomeArquivo: doc.data().nameA
            };
            array.push(Obj)
        });

        setRetorno13(array)
    }


    return (

        <View style={styles.container}>

            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Grupo 12</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff" />
                </TouchableOpacity>

            </View>

            <View style={styles.divImgBack}>
                <Image 
                    style={styles.imgBack} source={{
                    uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO.png?alt=media&token=7645261d-d233-404b-bfd9-b61b172ce0d0',
                }} />
            </View>

            <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 50, }}>
                <Text style={styles.titleMenu}>Módulos</Text>
            </View>

            <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', flexDirection: 'row', marginBottom: 70, maginTop: 100 }}>
                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("AnalisisFacialG12")}>
                    <Text style={retorno1.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Análisis Facial</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("CefalometriaG12")}>
                    <Text style={retorno12.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Cefalometría</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("CefalometriaPDFG12")}>
                    <Text style={retorno13.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Cefalometría (PDF Webceph)</Text>

                </TouchableOpacity>

            </View>

        </View>
    )
}