import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  Title: {
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    marginTop: 30,
  },

  container: {
    flex: 1,
    zoom: 0.65,
    backgroundColor: "#0F0F0F",
  },

  menu: {
    alingnItens: "center",
    height: 975,
    width: 330,
    borderTopRightRadius: 70,
    borderBottomRightRadius: 70,
    borderColor: "#29D8FF",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftColor: "#055669",
  },

  header: {
    height: 115,
    backgroundColor: "#FFFFFF15",
  },

  titleMenu: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#fff",
    marginTop: 10,
    marginBottom: 25,
    marginRight: 50,
    textAlign: "center",
  },

  buttomMenu: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    marginTop: 25,
    marginLeft: 40,
    borderWidth: 3,
    borderColor: "#FFF",
    borderStyle: "dashed",
    width: 370,
    height: 60,
  },

  MenuModulos: {
    fontSize: 25,
    textAlign: "center",
    color: "#000",
  },

  MenuDescription: {
    fontSize: 20,
    textAlign: "center",
    color: "#fff",
  },

  divImg: {
    alingnItens: "center",
    alignContent: "center",
    alignSelf: "center",
    marginTop: -80,
    width: 450,
  },

  img: {
    width: 450,
    height: 450,
  },

  buttomMenu2: {
    alignItems: "right",
    alignContent: "center",
    position: "absolute",
    marginTop: 150,
    marginLeft: 89,
  },

  MenuModulos2: {
    fontSize: 25,
    textAlign: "center",
    color: "#fff",
  },

  botaoDocs: {
    marginTop: 30,
    justifyContent: "center",
    alignContent: "center",
    alignContent: "center",
    alignSelf: "center",
    flexDirection: "row",
  },

  botaoModal: {
    alignItems: "flex-start",
    padding: 20,
    flexDirection: "row",
  },

  icon: {
    justifyContent: "center",
    alignContent: "center",
    marginTop: 7,
    padding: 5,
  },

  textBotao: {
    color: "#FFF",
    fontSize: 30,
    textAlign: "center",
    marginTop: 15,
    fontWeight: "bold",
  },

  textBotao2: {
    color: "#FFF",
    fontSize: 30,
    textAlign: "center",
    marginTop: 15,
    fontWeight: "bold",
  },

  textBotao3: {
    color: "#000",
    fontSize: 30,
    textAlign: "center",
    marginTop: 15,
    fontWeight: "bold",
  },

  botao1: {
    backgroundColor: "#512182",
    width: 380,
    height: 320,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 20,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
  },

  botao2: {
    backgroundColor: "#00DBCA",
    width: 380,
    height: 320,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 20,
  },

  botao3: {
    backgroundColor: "#FFF",
    width: 380,
    height: 320,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 20,
  },

  botao4: {
    backgroundColor: "#F77300",
    width: 380,
    height: 320,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 20,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
  },

  botao5: {
    backgroundColor: "#230F99",
    width: 380,
    height: 320,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },

  botao6: {
    backgroundColor: "#360d36",
    width: 380,
    height: 320,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    borderBottomLeftRadius: 100,
  },

  botao7: {
    backgroundColor: "#09515B",
    width: 380,
    height: 320,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    borderBottomRightRadius: 100,
  },

  logo: {
    width: 190,
    height: 150,
    marginTop: 13,
    marginBottom: 10,
    marginLeft: -20,
  },

  botaoGrupos: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 20,
  },

  iconGrupos: {
    justifyContent: "center",
    alignContent: "center",
  },

  modal: {
    width: 900,
    height: 600,
    alignContent: "center",
    alignSelf: "center",
    borderWidth: 1,
    borderColor: "#000000",
    marginTop: 200,
    borderRadius: 10,
    backgroundColor: "#FFF",
  },

  X: {
    top: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: "#FA002B",
    color: "#FFF",
    fontSize: 17,
  },

  DivAreaAluno: {
    position: "absolute",
    top: 0,
    marginTop: 30,
    marginLeft: 40,
  },

  BottomAreaAluno: {
    backgroundColor: "#E95964",
    width: 300,
    height: 70,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },

  textBottomAreaAluno: {
    color: "#FFF",
    textAlign: "center",
    fontSize: 23,
    fontWeight: "bold",
  },

  DivTestemunho: {
    position: "absolute",
    top: 0,
    right: 0,
    marginTop: 40,
    marginRight: 40,
    backgroundColor: "#FF944D",
    width: 511,
    height: 300,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
  },

  BottomTestemunho: {
    backgroundColor: "#E95964",
    width: 300,
    height: 70,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },

  textBottomTestemunho: {
    color: "#FFF",
    textAlign: "center",
    fontSize: 23,
    fontWeight: "bold",
  },

  imgTestemunho: {
    marginTop: 80,
    marginRight: 15,
    width: 480,
    height: 340,
  },

  divImgBack: {
    alingnItens: "center",
    alignContent: "center",
    alignSelf: "center",
    position: "absolute",
    opacity: 0.2,
    marginTop: 250,
  },

  imgBack: {
    width: 1920,
    height: 900,
  },
});

export default styles;
