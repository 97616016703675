import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore} from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';
import styles from './style';


export default function HomeGrupo11({ navigation }) {

    const [retorno1, setRetorno1] = useState([]);
    const [retorno12, setRetorno12] = useState([]);
    const [retorno13, setRetorno13] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [retorno3, setRetorno3] = useState([]);
    const [retorno4, setRetorno4] = useState([]);
    const [retorno5, setRetorno5] = useState([]);
    const [retorno6, setRetorno6] = useState([]);
    const [retorno7, setRetorno7] = useState([]);
    const [retorno8, setRetorno8] = useState([]);
    const [retorno9, setRetorno9] = useState([]);
    const [retorno10, setRetorno10] = useState([]);

    useEffect(() => {
        getResumos1();
        getResumos2();
        getResumos3();
        getResumos4();
        getResumos5();
        getResumos6();
        getResumos7();
        getResumos8();
        getResumos9();
        getResumos10();
        getResumos11();
        getResumos12();
    }, [])

    useFocusEffect(
        React.useCallback(() => {
            getResumos1();
            getResumos2();
            getResumos3();
            getResumos4();
            getResumos5();
            getResumos6();
            getResumos7();
            getResumos8();
            getResumos9();
            getResumos10();
            getResumos11();
            getResumos12();
        }, [])
      );

    const getResumos1 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Análisis Facial'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno1(array)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Cefalometría'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno12(array)
    }

    const getResumos3 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Cefalometría (pdf webceph)'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                url: doc.data().url,
                form: doc.data().nomeForm,
                status: doc.data().status,
                nomeArquivo: doc.data().nameA
            };
            array.push(Obj)
        });

        setRetorno13(array)
    }

    const getResumos4 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 2'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const getResumos5 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 3'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno3(array)
    }

    const getResumos6 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 4'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno4(array)
    }

    const getResumos7 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 5'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno5(array)
    }

    const getResumos8 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 6'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno6(array)
    }

    const getResumos9 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 7'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno7(array)
    }

    const getResumos10 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 8'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno8(array)
    }

    const getResumos11 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 9'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno9(array)
    }

    const getResumos12 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 10'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno10(array)
    }

    return (

        <View style={styles.container}>

            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Grupo 11</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff" />
                </TouchableOpacity>

            </View>

            <View style={styles.divImgBack}>
                <Image 
                    style={styles.imgBack} source={{
                    uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO.png?alt=media&token=7645261d-d233-404b-bfd9-b61b172ce0d0',
                }} />
            </View>

            <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', marginBottom: 50, marginTop: 20 }}>
                <Text style={styles.titleMenu}>Módulos</Text>
            </View>

            <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', flexDirection: 'row', marginBottom: 70, maginTop: 100 }}>
                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("HomeTareaG11")}>
                    <Text style={(retorno1.length+ retorno12.length + retorno13.length) >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 1</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo2G11")}>
                    <Text style={retorno2.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 2</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo3G11")}>
                    <Text style={retorno3.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 3</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo4G11")}>
                    <Text style={retorno4.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 4</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo5G11")}>
                    <Text style={retorno5.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 5</Text>

                </TouchableOpacity>

            </View>

            <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', flexDirection: 'row', marginTop: 30 }} >
                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo6G11")}>
                    <Text style={retorno6.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 6</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo7G11")}>
                    <Text style={retorno7.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 7</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo8G11")}>
                    <Text style={retorno8.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 8</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo9G11")}>
                    <Text style={retorno9.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 9</Text>

                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Modulo10G11")}>
                    <Text style={retorno10.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Módulo 10</Text>

                </TouchableOpacity>
            </View>


        </View>
    )
}