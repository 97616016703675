import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, Alert, TextInput, Linking, ScrollView } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import Toast from 'react-native-toast-message';
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc } from "firebase/firestore";
import axios from 'axios';
import styles from './style';


export default function CefalometriaPDFG12({ navigation }) {

    const [open, setOpen] = useState(false);
    const [openFotos, setOpenFotos] = useState(false);
    const [retorno2, setRetorno2] = useState([]);
    const [retorno3, setRetorno3] = useState([]);
    const [email, setEmail] = useState("");
    const [reload, setReload] = useState("")
    const [dataAtual, setDataAtual] = useState("")
    const [dupli, setDupli] = useState([]);

    const Tick =  () => {
        var data = new Date();

        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        setDataAtual(dia + '/' + mes + '/' + ano)

    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Cefalometría (pdf webceph)'), where("grupo", "==", "Grupo12"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                url: doc.data().url,
                novo: doc.data().novo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status,
                nomeArquivo: doc.data().nameA
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const getDuplicatas = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Cefalometría'), where("grupo", "==", "Grupo12"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                email: doc.data().email,
            };
            array.push(Obj)
        });

        setDupli(array)
    }

    async function CountResumos(email) {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email), where("status", "==", "ok"));
        let array = [];
    
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
    
            const Obj = {
              
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                form: doc.data().nomeForm,
    
            };
            array.push(Obj)
        });
    
    }
    
    useEffect(() => {
        EnviaPlanilha()
    }, [retorno2])
    
    async function EnviaPlanilha() {
        if(retorno2.length >= 7) {
            axios.post("https://hook.us1.make.com/64wb45b9l2aw2hfpl6qz77cvpxge4wwf",{
                nome: retorno2[0].nome,
                email: retorno2[0].email,
                grupo: retorno2[0].grupo,
            }).then(function (response) {
                console.log(response);
            }).catch(function (error) {
                console.error(error);
            });
    
        }
    }


    useEffect(() => {
        getResumos2();
        //getDuplicatas();
        setReload("");
        setOpen(false);
        Tick();
    }, [reload])

    //console.log(dupli)

    return (
        <>
            <View style={styles.container}>

                <View style={styles.header}>
                    <View>
                        <Text style={styles.Title}>Cefalometría pdf webceph (Grupo 12)</Text>
                    </View>
                    
                    <View style={{position: 'absolute', right: 0, marginRight: 20, marginTop: -10}}>
                        <Text style={styles.Title}>{retorno2.length}</Text>
                    </View>

                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.navigate('HomeGrupo12')} >
                        <MaterialCommunityIcons
                            name="home"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>

                </View>

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#fff", margin: 20,
                    }} >

                        <Text style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>Sem correção</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("CefalometriaPDFAprovadosG12")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Aprovados</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("CefalometriaPDFReprovadosG12")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Reprovados</Text>
                    </TouchableOpacity>

                </View>




                {retorno2 && retorno2.map((item) => {
                    return (
                        <>
                            <View style={styles.divReturn}>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>

                                <Text style={styles.textReturnGrupo}>{item.status}</Text>
                            </View>

                                {item.urlImg[1] === "" &&
                                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 20, marginTop: 10 }}>
                                    <TouchableOpacity style={styles.buttonView} onPress={() => {
                                        setOpenFotos(true)
                                        Linking.openURL(item.url);
                                        }}>
                                        <Text style={styles.textView}>Ver Resumos</Text>
                                    </TouchableOpacity>
                                </View>
                                }

                                {item.urlImg[0] !== "" &&
                                    <>
                                    <ScrollView
                                        horizontal={true}
                                    >
                                        {item.urlImg[0] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[0],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 1</Text>
                                            </View>

                                        }

                                        {item.urlImg[1] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[1],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 2</Text>
                                            </View>
                                        }

                                        {item.urlImg[2] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[2],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 3</Text>
                                            </View>

                                        }

                                        {item.urlImg[3] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[3],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 4</Text>
                                            </View>
                                        }

                                        {item.urlImg[4] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[4],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 5</Text>
                                            </View>
                                        }

                                        {item.urlImg[5] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[5],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 6</Text>
                                            </View>
                                        }

                                        {item.urlImg[6] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[6],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 7</Text>
                                            </View>
                                        }

                                        {item.urlImg[7] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[7],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 8</Text>
                                            </View>
                                        }

                                        {item.urlImg[8] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[8],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 9</Text>
                                            </View>
                                        }

                                        {item.urlImg[9] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[9],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 10</Text>
                                            </View>
                                        }

                                    </ScrollView>

                                    </> 
                                }
                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 30, marginTop: 20 }}>

                                    <TouchableOpacity style={styles.buttonProblema} onPress={() => { open === false ? setOpen(true) : setOpen(false)}}>

                                    <MaterialCommunityIcons
                                        name="close"
                                        size={25}
                                        color="#ffffff"
                                        style={{ alignSelf: 'center' }}
                                    />

                                    <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}> { open === false ? "Reprovar" : "Cancelar" }</Text>

                                    </TouchableOpacity>


                                    {open === false &&

                                    <TouchableOpacity style={styles.buttonOk} onPress={async () => {

                                        axios.post(
                                            "https://hook.us1.make.com/eo0uf9x9xxq3ir5gqsma2md4i6fi1dph",
                                            {
                                                email: item.email,
                                                status: "✅",
                                            }
                                        )
                                        .then(function (response) {
                                            console.log(response);
                                        })
                                        .catch(function (error) {
                                            console.error(error);
                                        });

                                        const db = getFirestore();
                                        const docRef = await addDoc(collection(db, "Resumos"), {
                                            to: [item.email],
                                            message: {
                                                subject: 'G12 - Módulo 1 - Resumen aprobado',
                                                html: '<p>Hola Doc. ' + (item.nome).split(' ')[0] + ', ¡saludos! </p><p>Tu resumen de ' + item.form + ' fue aprobado.</p><p>Seguimos en contacto.</p><p>Equipo de Soporte Profª Rayane Pinto<br/>Odontología Sin Fronteras Cursos e Treinamentos LTDA</p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                            },
                                            nome: item.nome,
                                            formG12C3: item.form,
                                            email: item.email,
                                            ifoUsuario: item.grupo,
                                            url: item.url,
                                            urlImg: item.urlImg,
                                            status: "ok",
                                            data: dataAtual
                                        });

                                        const docRef2 = await addDoc(collection(db, "Concluido"), {
                                            nomeForm: "Cefalometría (pdf webceph)",
                                            email: item.email,
                                            status: "ok",
                                            });

                                        CountResumos(item.email);
                                        
                                        await deleteDoc(doc(db, "Resumos", item.id));
                                        alert("Email de aprovação Enviado para o aluno", "...")
                                       
                                        setReload("Recarrega")

                                    }}>

                                        <MaterialCommunityIcons
                                            name="check-bold"
                                            size={25}
                                            color="#ffffff"
                                            style={{ alignSelf: 'center' }}
                                        />
                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Aprovar</Text>
                                    </TouchableOpacity>
                                    }

                                </View>
                            

                            <View style={{ justifyContent: 'center', alignSelf: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 100 }}>
                                
                            {open === true &&
                                    <TextInput style={{
                                        alignItems: 'center', width: 600, marginTop: 10, padding: 30,
                                        height: 150, marginBottom: 10,
                                        borderWidth: 1, borderColor: "#000000", color: "#000000", backgroundColor: '#fff',
                                        borderRadius: 30,
                                    }}
                                        placeholder="Motivo de reprovação..."
                                        placeholderTextColor="#000000"
                                        type="text"
                                        multiline = {true}
                                        numberOfLines = {4}
                                        onChangeText={(text) => setEmail(text)}
                                        value={email} />
                            }

                                {open === true && email === "" &&

                                    <TouchableOpacity style={{
                                        width: 200,
                                        height: 50,
                                        backgroundColor: '#FF6553',
                                        justifyContent: 'center',
                                        borderRadius: 30,
                                    }}>

                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Enviar</Text>
                                    </TouchableOpacity>

                                }
                                {email !== "" &&

                                    <TouchableOpacity style={styles.buttonProblema2} onPress={async () => {

                                        const db = getFirestore();
                                        const docRef = await addDoc(collection(db, "Resumos"), {
                                            to: [item.email],
                                            message: {
                                                subject: 'G12 - Módulo 1 - Resumen desaprobado',
                                                html: '<p>Hola Doc. ' + (item.nome).split(' ')[0] + ', ¡saludos! </p><p> Debes enviar nuevamente el resumen de ' + item.form + '.<p>No fue aceptado porque: ' + email + '</p></p><p>Seguimos en contacto.</p><p>Equipo de Soporte Profª Rayane Pinto<br/>Odontología Sin Fronteras Cursos e Treinamentos LTDA</p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                            },
                                            nome: item.nome,
                                            formG12C4: item.form,
                                            email: item.email,
                                            urlImg: item.urlImg,
                                            ifoUsuario: item.grupo,
                                            url: item.url,
                                            status: "Reprovado",
                                            data: dataAtual
                                        });

                                        await deleteDoc(doc(db, "Resumos", item.id));
                                        setReload("Recarrega")
                                        alert("Email de reprovação Enviado para o aluno", "...")
                                        
                                    }}>
                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Enviar</Text>
                                    </TouchableOpacity>
                                }

                            </View>

                        </>
                    )
                })

                }

            </View>
        </>
    )

}