import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Alert, TextInput } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import Toast from 'react-native-toast-message';
import { collection, query, where, getDocs, getFirestore, orderBy } from "firebase/firestore";
import styles from './style';



export default function AnalisisAprovadosG12({ navigation }) {

    const [open, setOpen] = useState(false);
    const [openFotos, setOpenFotos] = useState(false);
    const [retorno, setRetorno] = useState([]);
    const [order, setOrder] = useState("asc")
    const [email, setEmail] = useState("")


    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG12A1", "==", 'Análisis Facial'), where("status", "==", 'ok'), where("delivery.startTime", "!=", ''), orderBy('delivery.startTime', order));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().formÇ,
                status: doc.data().status,
                data : doc.data().data
            };
            array.push(Obj)
        });

        setRetorno(array)
    }


    useEffect(() => {
        getResumos();
    }, [])


    useEffect(() => {
        getResumos();
    }, [order])

    return (
        <>
            <View style={styles.container}>

                <View style={styles.header}>
                    <View>
                        <Text style={styles.Title}>Análisis Facial (Grupo 12)</Text>
                    </View>

                    <View style={{position: 'absolute', right: 0, marginRight: 20, marginTop: -10}}>
                        <Text style={styles.Title}>{retorno.length}</Text>
                    </View>

                    <Toast ref={(ref) => { Toast.setRef(ref) }} />

                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.navigate('HomeGrupo12')} >
                        <MaterialCommunityIcons
                            name="home"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>

                </View>

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("AnalisisFacialG12")}>

                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Sem correção</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#fff", margin: 20 }} onPress={() => navigation.navigate("AnalisisAprovadosG12")}>
                        <Text style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>Aprovados</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("AnalisisReprovadosG12")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Reprovados</Text>
                    </TouchableOpacity>

                    <View style={{position: 'absolute', right: 0, marginRight: 80, marginTop: 25}}>
                        <TouchableOpacity onPress={() => {order === "asc" ? setOrder("desc") : setOrder("asc") }}>
                      <MaterialCommunityIcons
                            name={order === "asc" ? "filter-outline" : "filter"}
                            size={60}
                            color="#ffffff" />
                           
                        </TouchableOpacity>
                    </View>

                </View>


                <View style={{position: 'absolute', left: 0, top: 0, marginTop: 130, marginLeft: 30}}>
                    <TouchableOpacity onPress={async () => navigation.navigate('AnalisisFavoritosG12')}>
                        <MaterialCommunityIcons
                        name="star"
                        size={65}
                        color="#F4E428"
                        style={{ alignSelf: 'center' }}
                        />
                    </TouchableOpacity>
                </View>


                {retorno && retorno.map((item) => {
                    return (
                        <>
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 35}}>Data: {item.data}</Text>                               
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnGrupo}>{item.status}</Text>
                            </View>

                            {openFotos === false &&
                                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 20, marginTop: 10 }}>
                                    <TouchableOpacity style={styles.buttonView} onPress={() => {setOpenFotos(true), setEmail(item.email)}}>
                                        <Text style={styles.textView}>Ver Resumos</Text>
                                    </TouchableOpacity>
                                </View>
                            }


                            {openFotos === true && item.email === email &&
                                <View style={styles.divImg}>

                                    {openFotos === true &&
                                        <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 20, marginTop: 20 }}>
                                            <TouchableOpacity style={styles.buttonView} onPress={({ item }) => setOpenFotos(false)}>
                                                <Text style={styles.textView}>Fechar</Text>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                    <ScrollView
                                        horizontal={true}
                                    >
                                        {item.urlImg[0] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[0],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 1</Text>
                                            </View>

                                        }

                                        {item.urlImg[1] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[1],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 2</Text>
                                            </View>
                                        }

                                        {item.urlImg[2] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[2],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 3</Text>
                                            </View>

                                        }

                                        {item.urlImg[3] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[3],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 4</Text>
                                            </View>
                                        }

                                        {item.urlImg[4] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[4],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 5</Text>
                                            </View>
                                        }

                                        {item.urlImg[5] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[5],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 6</Text>
                                            </View>
                                        }

                                        {item.urlImg[6] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[6],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 7</Text>
                                            </View>
                                        }

                                        {item.urlImg[7] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[7],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 8</Text>
                                            </View>
                                        }

                                        {item.urlImg[8] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[8],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 9</Text>
                                            </View>
                                        }

                                        {item.urlImg[9] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[9],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 10</Text>
                                            </View>
                                        }

                                        {item.urlImg[10] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[10],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 11</Text>
                                            </View>
                                        }

                                        {item.urlImg[11] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[11],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 12</Text>
                                            </View>
                                        }

                                        {item.urlImg[12] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[12],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 13</Text>
                                            </View>
                                        }

                                        {item.urlImg[13] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[13],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 14</Text>
                                            </View>
                                        }

                                        {item.urlImg[14] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[14],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 15</Text>
                                            </View>
                                        }

                                        {item.urlImg[15] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[15],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 16</Text>
                                            </View>
                                        }

                                        {item.urlImg[16] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[16],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 17</Text>
                                            </View>
                                        }

                                        {item.urlImg[17] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[17],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 18</Text>
                                            </View>
                                        }

                                        {item.urlImg[18] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[18],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 19</Text>
                                            </View>
                                        }

                                        {item.urlImg[19] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[19],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 20</Text>
                                            </View>
                                        }

                                        {item.urlImg[20] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[20],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 21</Text>
                                            </View>
                                        }

                                        {item.urlImg[21] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[21],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 22</Text>
                                            </View>
                                        }

                                        {item.urlImg[22] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[22],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 23</Text>
                                            </View>
                                        }

                                        {item.urlImg[23] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[23],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 24</Text>
                                            </View>
                                        }

                                        {item.urlImg[24] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[24],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 25</Text>
                                            </View>
                                        }

                                    </ScrollView>

                                </View>
                            }
                        </>
                    )
                })

                }

            </View>
        </>
    )

}