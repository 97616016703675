import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  Title: {
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    marginTop: 20,
  },

  container: {
    flex: 1,
    zoom: 0.65,
    backgroundColor: "#0F0F0F",
  },

  menu: {
    justifyContent: "left",
    alingnItens: "center",
    height: 975,
    width: 300,
    borderTopRightRadius: 70,
    borderBottomRightRadius: 70,
    borderColor: "#29D8FF",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftColor: "#055669",
  },

  header: {
    height: 115,
    backgroundColor: "#FFFFFF15",
  },

  titleMenu: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#fff",
    marginTop: 10,
    marginBottom: 25,
    marginLeft: 60,
  },

  buttomMenu: {
    alignItems: "left",
    alignContent: "center",
    position: "absolute",
    marginTop: 30,
    marginLeft: 40,
  },

  MenuModulos: {
    fontSize: 20,
    textAlign: "center",
    color: "#fff",
  },

  MenuDescription: {
    fontSize: 15,
    textAlign: "center",
    color: "#fff",
  },

  divReturn: {
    backgroundColor: "#fff",
    alignItems: "left",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    height: 150,
    width: 1000,
    margin: 30,
    borderRadius: 30,
  },

  textReturnTitle: {
    textAlign: "left",
    fontSize: 25,
    marginTop: 10,
    marginLeft: 25,
    fontWeight: "bold",
  },

  textReturnEmail: {
    textAlign: "left",
    fontSize: 15,
    marginTop: 30,
    marginLeft: 25,
  },

  textReturnGrupo: {
    textAlign: "left",
    fontSize: 15,
    marginTop: 5,
    marginLeft: 25,
  },

  img: {
    width: 740,
    height: 985.5,
    margin: 30,
  },
  divImg: {
    alingnItens: "center",
    alignContent: "center",
    backgroundColor: "transparent",
    marginLeft: 30,
    marginRight: 30,
  },

  buttonView: {
    width: 200,
    height: 50,
    backgroundColor: "#EB0101",
    borderRadius: 50,
    justifyContent: "center",
  },

  textView: {
    textAlign: "center",
    fontSize: 15,
    color: "#fff",
  },

  buttonOk: {
    width: 200,
    height: 50,
    backgroundColor: "#00EB73",
    justifyContent: "center",
    borderRadius: 30,
  },

  buttonProblema: {
    width: 200,
    height: 50,
    backgroundColor: "#EB0101",
    justifyContent: "center",
    marginRight: 30,
    borderRadius: 30,
  },

  buttonProblema2: {
    width: 200,
    height: 50,
    backgroundColor: "#EB0101",
    justifyContent: "center",
    borderRadius: 30,
  },

  buttonOk2: {
    width: 200,
    height: 50,
    backgroundColor: "#F4E428",
    justifyContent: "center",
    borderRadius: 30,
    marginLeft: 30,
  },

  modalBackground: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContainer: {
    width: 300,
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    alignItems: "center",
  },
  modalText: {
    marginBottom: 20,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  modalButton: {
    backgroundColor: "#13EBB1",
    padding: 10,
    borderRadius: 5,
    margin: 5,
  },

  modalButton2: {
    backgroundColor: "#EA0038",
    padding: 10,
    borderRadius: 5,
    margin: 5,
  },
});

export default styles;
