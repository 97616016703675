import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  Title: {
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    marginTop: 20,
  },

  container: {
    flex: 1,
    zoom: 0.75,
    backgroundColor: "#0F0F0F"
  },

  menu: {
    alingnItens: "center",
    height: 975,
    width: 300,
    borderTopRightRadius: 70,
    borderBottomRightRadius: 70,
    borderColor: "#000",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftColor: "#000",
  },

  header: {
    height: 115,
    backgroundColor: "#FFFFFF15",
  },

  titleMenu: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#fff",
    marginTop: 10,
    marginBottom: 25,
    marginLeft: 25,
  },

  buttomMenu: {
    alignItems: "left",
    alignContent: "center",
    position: "absolute",
    marginTop: 10,
    marginLeft: 40,
  },

  MenuModulos: {
    fontSize: 25,
    textAlign: "center",
    color: "#000",
    marginTop: 25,
  },

  MenuDescription: {
    fontSize: 20,
    textAlign: "center",
    color: "#fff",
  },

  divImg: {
    alingnItens: "center",
    alignContent: "center",
    alignSelf: "center",
  },

  img: {
    width: 700,
    height: 700,
  },

  buttomMenu2: {
    alignItems: "right",
    alignContent: "center",
    position: "absolute",
    marginTop: 150,
    marginLeft: 89,
  },

  MenuModulos2: {
    fontSize: 25,
    textAlign: "center",
    color: "#fff",
  },

  textNome: {
    color: "#FFF",
    fontSize: 35,
    textAlign: "center",
    marginTop: -100,
    marginLeft: 20,
    marginRight: 20,
  },

  viewBotao: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    marginTop: 60,
    marginBottom: 60,
  },

  botaoCaso: {
    backgroundColor: "#FFF",
    borderRadius: 20,
    width: 550,
    height: 90,
  },

  divReturn: {
    backgroundColor: "#fff",
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    height: 150,
    width: 1000,
    margin: 120,
    borderRadius: 30,
  },

  textReturnTitle: {
    textAlign: "left",
    fontSize: 25,
    marginTop: 10,
    marginLeft: 25,
    fontWeight: "bold",
  },

  textReturnEmail: {
    textAlign: "left",
    fontSize: 15,
    marginTop: 30,
    marginLeft: 25,
  },

  textReturnEmail2: {
    textAlign: "left",
    fontSize: 15,
    marginTop: 10,
    marginLeft: 25,
    marginBottom: 10,
  },

  navBar: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    marginTop: 60,
    marginBottom: 50,
  },
  NavActive: {
    backgroundColor: "transparent",
    borderBottomWidth: 1,
    borderBottomColor: "#FFF",
    margin: 20,
  },

  textNavActive: {
    color: "#FFF",
    fontSize: 25,
    fontWeight: "bold",
  },

  NavDesable: {
    backgroundColor: "transparent",
    borderBottomWidth: 1.5,
    borderBottomColor: "#FFFFFF70",
    margin: 20,
  },

  textNavDesable: {
    color: "#FFFFFF70",
    fontSize: 25,
    fontWeight: "bold",
  },

  buttonFechar: {
    position: "absolute",
    backgroundColor: "#F0003A",
    width: 70,
    height: 70,
    marginTop: 30,
    marginRight: 60,
    borderRadius: 50,
    top: 0,
    right: 0,
  },

  textButtonFechar: {
    color: "#fff",
    textAlign: "center",
    fontSize: 20,
    marginTop: 18,
  },

  alinhadorDocs: {
    justifyContent: "center",
    alignItems: "center",
    margin: 20,
  },

  textDoc: {
    color: "#FFF",
    fontSize: 25,
    textAlign: "center",
  },

  buttonDoc: {
    backgroundColor: "#E6A70B",
    width: 190,
    height: 60,
    margin: 30,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
  },

  textButtonDoc: {
    color: "#FFF",
    fontSize: 19,
    textAlign: "center",
    fontWeight: "700",
  },

  alinhadorCet: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 150,
  },

  alinhadorEnviado: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 60,
  },

  alinhadorEnviadoInterno: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 60,
    flexDirection: "row",
  },

  buttonEnv: {
    backgroundColor: "#005BF5",
    width: 150,
    height: 60,
    marginTop: 10,
    borderRadius: 20,
  },

  textEnv: {
    color: "#fff",
    textAlign: "center",
    fontSize: 20,
    marginTop: 15,
  },

  buttonProblema: {
    width: 150,
    height: 60,
    marginTop: 10,
    borderRadius: 20,
    backgroundColor: "#EB0101",
    justifyContent: "center",
    marginRight: 30,
  },

  textProblema: {
    textAlign: "center",
    fontSize: 20,
    color: "#fff",
  },

  input: {
    alignItems: "center",
    width: 600,
    marginTop: 25,
    padding: 25,
    height: 150,
    marginBottom: 10,
    textAlign: "justify",
    borderWidth: 1,
    borderColor: "#000000",
    color: "#000000",
    backgroundColor: "#fff",
    borderRadius: 30,
  },
});

export default styles;
