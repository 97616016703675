import React, { useEffect, useState } from "react";
import { LogBox, View, Text, TextInput, TouchableOpacity } from 'react-native';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import styles from "./style";
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import * as Animatable from 'react-native-animatable';
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import firebase from "../Config/firebaseconfig";

export default function Login({ navigation }) {
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [errorRegister, setErrorRegister] = useState("");
    const [errorRegister2, setErrorRegister2] = useState(false);
    const [logar, setLogar] = useState("");
    const [visu, setVisu] = useState(true);
    const [eye, setEye] = useState("eye");
    const [at, setAt] = useState([]);
    const [t, setT] = useState("");
    const [ata, setAtA] = useState([]);
    const [ta, setTA] = useState("");

    useEffect(() => {
        // Alterna o ícone do olho (visível/invisível) baseado no estado de visualização da senha
        if (visu === false) {
            setEye("eye");
        } else {
            setEye("eye-off");
        }
    }, [visu]);

    const login = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, (email).trim().toLowerCase(), senha)
            .then((userCredential) => {
                let user = userCredential.user;
                autenticação();
                autenticaçãoProf();
            })
            .catch((error) => {
                setErrorRegister(true);
                setErrorRegister2(true);
                let errorCode = error.code;
                let errorMessage = error.message;
            });
    };

    useEffect(() => {
        // Verifica se o usuário está autenticado
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                navigation.navigate("Home");
            }
        });
    }, []);

    const handleKeyPress = ({ nativeEvent: { key: keyValue } }) => {
        // Ativa o login ao pressionar a tecla Enter
        if (keyValue === 'Enter') {
            setLogar("Ativar");
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            // Reseta os estados ao focar na tela de login
            setSenha("");
            setErrorRegister(false);
            setLogar("");
        }, [])
    );

    useEffect(() => {
        if (logar === "Ativar") {
            login();
        }
    }, [logar]);

    useEffect(() => {
        if (errorRegister2 === true) {
            // Reseta os estados em caso de erro no registro
            setSenha("");
            setLogar("");
            setT("");
            setErrorRegister2(false);
        }
        console.log(errorRegister2);
    }, [errorRegister2]);

    async function autenticação() {
        const db = getFirestore();
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("suporte", "==", 'Sim'), where("email", "==", (email).trim().toLowerCase()));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
            };
            array.push(Obj);
        });

        setAt(array);
        setT("Tentou");
    }

    async function autenticaçãoProf() {
        const db = getFirestore();
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("Professor", "==", 'Sim'), where("email", "==", (email).trim().toLowerCase()));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
            };
            array.push(Obj);
        });

        setAtA(array);
        setTA("Tentou");
    }

    useEffect(() => {
        if (at.length > 0) {
            navigation.navigate("Home");
        }
    }, [t]);

    useEffect(() => {
        if (ata.length > 0) {
            navigation.navigate("Home");
        }
    }, [ta]);

    return (
        <View style={styles.container}>
            <View style={styles.divImg}>
                <Animatable.Image
                    animation="fadeInDown"
                    style={styles.img}
                    source={{
                        uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO.png?alt=media&token=7645261d-d233-404b-bfd9-b61b172ce0d0',
                    }}
                />
            </View>

            <Animatable.Text animation="fadeInLeftBig" style={styles.title}>Login</Animatable.Text>

            <Animatable.View animation="slideInLeft">
                <TextInput
                    style={{
                        alignItems: 'center',
                        width: 500,
                        marginTop: 30,
                        padding: 10,
                        height: 60,
                        borderBottomWidth: 1,
                        borderBottomColor: "#fff",
                        marginLeft: "auto",
                        marginRight: "auto",
                        color: "#fff",
                        fontSize: 17,
                        fontWeight: "bold"
                    }}
                    placeholder="E-mail..."
                    placeholderTextColor="#fff"
                    type="text"
                    onChangeText={(text) => setEmail(text)}
                    value={email}
                />
            </Animatable.View>

            <Animatable.View
                animation="slideInRight"
                style={{
                    flexDirection: "row",
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: "center",
                    alignSelf: "center"
                }}
            >
                <TextInput
                    style={{
                        alignItems: 'center',
                        width: 500,
                        marginTop: 30,
                        padding: 10,
                        height: 60,
                        borderBottomWidth: 1,
                        borderBottomColor: "#fff",
                        color: "#fff",
                        fontSize: 17,
                        fontWeight: "bold"
                    }}
                    secureTextEntry={visu}
                    placeholder="Senha..."
                    placeholderTextColor="#fff"
                    type="text"
                    onChangeText={(text) => setSenha(text)}
                    value={senha}
                    onKeyPress={handleKeyPress}
                />

                <TouchableOpacity style={{ marginLeft: -30, marginTop: 40 }} onPress={() => visu === false ? setVisu(true) : setVisu(false)}>
                    <MaterialCommunityIcons
                        name={eye}
                        size={30}
                        color="#FFE"
                    />
                </TouchableOpacity>
            </Animatable.View>

            {errorRegister === true ?
                <View style={styles.contentAlert}>
                    <MaterialCommunityIcons
                        name="alert-circle"
                        size={24}
                        color="#FFE500"
                    />
                    <Text style={styles.warningAlert}>E-mail ou Senha Incorretos</Text>
                </View>
                :
                <View />
            }

            <View style={{ alignSelf: 'center' }}>
                {email === "" || senha === "" ?
                    <Animatable.View animation="zoomIn">
                        <TouchableOpacity
                            animation="zoomIn"
                            disabled={true}
                            style={styles.buttonLogin}
                        >
                            <Text style={styles.textButtonLogin}>Entrar</Text>
                        </TouchableOpacity>
                    </Animatable.View>
                    :
                    <TouchableOpacity
                        style={styles.buttonLogin}
                        onPress={login}
                    >
                        <Text style={styles.textButtonLogin}>Entrar</Text>
                    </TouchableOpacity>
                }

                <Text style={styles.registation}>
                    <TouchableOpacity onPress={() => navigation.navigate("Trocadesenha")}>
                        <Text style={styles.linkSubscribe}>
                            Troca de senha
                        </Text>
                    </TouchableOpacity>
                </Text>
            </View>

            <View style={{ height: 100 }} />
        </View>
    );
}
