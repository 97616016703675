import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, ScrollView } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { VictoryChart, VictoryGroup, VictoryBar, VictoryTheme} from 'victory';
import styles from './style';
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable'

export default function RelatorioG11({ navigation }) {

    const [open, setOpen] = useState(false)

    const [retorno1, setRetorno1] = useState([])
    const [retorno2, setRetorno2] = useState([])
    const [retorno3, setRetorno3] = useState([])

    const [retorno4, setRetorno4] = useState([])
    const [retorno5, setRetorno5] = useState([])
    const [retorno6, setRetorno6] = useState([])

    const [retorno7, setRetorno7] = useState([])
    const [retorno8, setRetorno8] = useState([])
    const [retorno9, setRetorno9] = useState([])

    const [retorno10, setRetorno10] = useState([])
    const [retorno11, setRetorno11] = useState([])
    const [retorno12, setRetorno12] = useState([])

    const [retorno13, setRetorno13] = useState([])
    const [retorno14, setRetorno14] = useState([])
    const [retorno15, setRetorno15] = useState([])

    const [retorno16, setRetorno16] = useState([])
    const [retorno17, setRetorno17] = useState([])
    const [retorno18, setRetorno18] = useState([])

    const [retorno19, setRetorno19] = useState([])
    const [retorno20, setRetorno20] = useState([])
    const [retorno21, setRetorno21] = useState([])

    const [retorno22, setRetorno22] = useState([])
    const [retorno23, setRetorno23] = useState([])
    const [retorno24, setRetorno24] = useState([])

    const [retorno25, setRetorno25] = useState([])
    const [retorno26, setRetorno26] = useState([])
    const [retorno27, setRetorno27] = useState([])

    const [retorno28, setRetorno28] = useState([])
    const [retorno29, setRetorno29] = useState([])
    const [retorno30, setRetorno30] = useState([])

    const [retorno31, setRetorno31] = useState([])
    const [retorno32, setRetorno32] = useState([])
    const [retorno33, setRetorno33] = useState([])

    const [retorno34, setRetorno34] = useState([])
    const [retorno35, setRetorno35] = useState([])
    const [retorno36, setRetorno36] = useState([])

    const [totalA, setTotalA] = useState([])
    const [t1, setT1] = useState([])
    const [t2, setT2] = useState([])

    const getResumos1 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("grupo", "==", "Grupo11"), where("nomeForm", "==", 'Análisis Facial'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno1(array)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("grupo", "==", "Grupo11"), where("nomeForm", "==", 'Cefalometría'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const getResumos3 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("grupo", "==", "Grupo11"), where("nomeForm", "==", 'Cefalometría (pdf webceph)'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno3(array)
    }

    const getResumos4 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG11A1", "==", 'Análisis Facial'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno4(array)
    }

    const getResumos5 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG11C1", "==", 'Cefalometría'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().formH,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno5(array)
    }

    const getResumos6 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG11C3", "==", 'Cefalometría (pdf webceph)'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().formMNBV,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno6(array)
    }

    const getResumos7 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG11A2", "==", 'Análisis Facial'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno7(array)
    }

    const getResumos8 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG11C2", "==", 'Cefalometría'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormQ,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno8(array)
    }

    const getResumos9 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG11C4", "==", 'Cefalometría (pdf webceph)'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormNBHG,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno9(array)
    }

    const getResumos10 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 2'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno10(array)
    }

    const getResumos11 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 2'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno11(array)
    }

    const getResumos12 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 2'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno12(array)
    }

    const getResumos13 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 3'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno13(array)
    }

    const getResumos14 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 3'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno14(array)
    }

    const getResumos15 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 3'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno15(array)
    }

    const getResumos16 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 4'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno16(array)
    }

    const getResumos17 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 4'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno17(array)
    }

    const getResumos18 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 4'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno18(array)
    }

    const getResumos19 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 5'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno19(array)
    }

    const getResumos20 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 5'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno20(array)
    }

    const getResumos21 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 5'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno21(array)
    }

    const getResumos22 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 6'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno22(array)
    }

    const getResumos23 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 6'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno23(array)
    }

    const getResumos24 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 6'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno24(array)
    }

    const getResumos25 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 7'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno25(array)
    }

    const getResumos26 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 7'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno26(array)
    }

    const getResumos27 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 7'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno27(array)
    }

    const getResumos28 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 8'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno28(array)
    }

    const getResumos29 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 8'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno29(array)
    }

    const getResumos30 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 8'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno30(array)
    }

    const getResumos31 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 9'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno31(array)
    }

    const getResumos32 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 9'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno32(array)
    }

    const getResumos33 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 9'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno33(array)
    }

    const getResumos34 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 10'), where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno34(array)
    }

    const getResumos35 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG111", "==", 'Módulo 10'), where("status", "==", 'ok'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno35(array)
    }

    const getResumos36 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG112", "==", 'Módulo 10'), where("status", "==", 'Reprovado'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                form: doc.data().FormRlsjkj,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setRetorno36(array)
    }

    const getTotal = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("grupo", "==", 'Grupo11'));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().grupo,
            };
            array.push(Obj)
        });

        setTotalA(array)
    }
    
    const getTotalG2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("grupo", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setT1(array)
    }

    const getTotalG2_1 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("ifoUsuario", "==", "Grupo11"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                urlImg: doc.data().urlImg,
                status: doc.data().status
            };
            array.push(Obj)
        });

        setT2(array)
    }

    useEffect(() => {
        getResumos1();
        getResumos2();
        getResumos3();
        getResumos4();
        getResumos5();
        getResumos6();
        getResumos7();
        getResumos8();
        getResumos9();
        getResumos10();
        getResumos11();
        getResumos12();
        getResumos13();
        getResumos14();
        getResumos15();
        getResumos16();
        getResumos17();
        getResumos18();
        getResumos19();
        getResumos20();
        getResumos21();
        getResumos22();
        getResumos23();
        getResumos24();
        getResumos25();
        getResumos26();
        getResumos27();
        getResumos28();
        getResumos29();
        getResumos30();
        getResumos31();
        getResumos32();
        getResumos33();
        getResumos34();
        getResumos35();
        getResumos36();
        getTotal();
        getTotalG2();
        getTotalG2_1();
    }, [])

    console.log("t1:", t1)
    console.log("t2:", t2)
    useFocusEffect(
        React.useCallback(() => {
            setOpen(false);
        }, [])
      );

    return (

        <View style={styles.container}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Relatório</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff" />
                </TouchableOpacity>
                
            </View>


            {open !== true &&
            <>
            <Text style={{fontSize: 50, color: "#fff", textAlign: "center", fontWeight: "bold", marginTop: 20 }}>Grupo 11</Text>

            <Animatable.View animation="fadeInDownBig" style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', marginTop: 50, backgroundColor: '#fff',  borderRadius: 20,}}>
            
                <Text style={{fontSize: 30, color: "#000", textAlign: "center", marginTop: 30}}>Gráfico de relação geral ao Módulo 1</Text>

                <View style={{flexDirection: "row", width: 1700, height: 700}}>
                <Text style={{fontSize: 30, marginTop: 300, marginLeft: 30}}>% de entregas dos alunos por módulo</Text>
                
                    <VictoryChart
                    theme={VictoryTheme.material}
                    width={600}
                    domain={totalA.length}
                    >

                    <VictoryGroup
                    offset={12}
                    colorScale={["#8500FA", "#0C0FFA", "#FA0CD8"]}
                    style={{color: "#fff"}}
                    >
                        <VictoryBar data={[{ x: "Analisís Facial (" + `${retorno1.length + retorno4.length + retorno7.length}` + ")", 
                        y: (((retorno1.length + retorno4.length + retorno7.length)/ totalA.length) * 100) }]} barWidth={30} alignment="start"
                        labels={({ datum }) => `${(((retorno1.length + retorno4.length + retorno7.length)/ totalA.length) * 100).toFixed(2)}%`}
                        />

                        <VictoryBar data={[{ x: "Cefalometría (" + `${retorno2.length + retorno5.length + retorno8.length}` + ")",
                         y: (((retorno2.length + retorno5.length + retorno8.length)/ totalA.length) * 100)}]} barWidth={30}
                        labels={({ datum }) => `${(((retorno2.length + retorno5.length + retorno8.length)/ totalA.length) * 100).toFixed(2)}%`}
                        />

                        <VictoryBar data={[{ x: "Cefalometría (pdf webceph), (" + `${retorno3.length + retorno6.length + retorno9.length}` + ")", 
                        y: (((retorno3.length + retorno6.length + retorno9.length)/ totalA.length) * 100)}]} barWidth={30}
                        labels={({ datum }) => `${(((retorno3.length + retorno6.length + retorno9.length)/ totalA.length) * 100).toFixed(2)}%`}
                        />

                        </VictoryGroup>

                    </VictoryChart>

                </View>
            </Animatable.View>


            <Animatable.View  animation="fadeInDownBig" style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', marginTop: 30, backgroundColor: '#fff'}}>
            
            <Text style={{fontSize: 30, color: "#000", textAlign: "center", marginTop: 30}}>Gráfico de relação geral dos Módulos 2 ao 10</Text>
            <View style={{flexDirection: "row", width: 1700, height: 700}}>
                <Text style={{fontSize: 30, marginTop: 300, marginLeft: 30}}>% de entregas dos alunos por módulo</Text>

                <VictoryChart
                theme={VictoryTheme.material}
                width={800}
                domain={totalA.length}
                >
                <VictoryGroup
                offset={4}
                colorScale={["#FFA800","#17D2E3","#8500FA","#FA0CD8", "#0C0FFA", "#FF7D1A", "#0DFFBF","#FF018E", "#0D97FF", "#0C0FFA",]}
                style={{color: "#fff"}}
                >
                    <VictoryBar data={[{ x: "Módulo 2 \n (" + `${retorno10.length + retorno11.length + retorno12.length}` + ")",
                     y: (((retorno10.length + retorno11.length + retorno12.length)/ totalA.length) * 100) }]} barWidth={25} alignment="start"
                    labels={({ datum }) => `${(((retorno10.length + retorno11.length + retorno12.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Módulo 3 \n (" + `${retorno13.length + retorno14.length + retorno15.length}` + ")", 
                    y: (((retorno13.length + retorno14.length + retorno15.length)/ totalA.length) * 100) }]}  barWidth={25} 
                    labels={({ datum }) => `${(((retorno13.length + retorno14.length + retorno15.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Módulo 4 \n (" + `${retorno16.length + retorno17.length + retorno18.length}` + ")",
                    y: (((retorno16.length + retorno17.length + retorno18.length)/ totalA.length) * 100) }]} barWidth={25} 
                    labels={({ datum }) => `${(((retorno16.length + retorno17.length + retorno18.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Módulo 5 \n (" + `${retorno19.length + retorno20.length + retorno21.length}` + ")", 
                    y: (((retorno19.length + retorno20.length + retorno21.length)/ totalA.length) * 100) }]} barWidth={25} 
                    labels={({ datum }) => `${(((retorno19.length + retorno20.length + retorno21.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Módulo 6 \n (" + `${retorno22.length + retorno23.length + retorno24.length}` + ")", 
                    y: (((retorno22.length + retorno23.length + retorno24.length)/ totalA.length) * 100) }]} barWidth={25} 
                    labels={({ datum }) => `${(((retorno22.length + retorno23.length + retorno24.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Módulo 7 \n (" + `${retorno25.length + retorno26.length + retorno27.length}` + ")", 
                    y: (((retorno25.length + retorno26.length + retorno27.length)/ totalA.length) * 100) }]} barWidth={25}
                    labels={({ datum }) => `${(((retorno25.length + retorno26.length + retorno27.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Módulo 8 \n (" + `${retorno28.length + retorno29.length + retorno30.length}` + ")",
                     y: (((retorno28.length + retorno29.length + retorno30.length)/ totalA.length) * 100) }]} barWidth={25} 
                    labels={({ datum }) => `${(((retorno28.length + retorno29.length + retorno30.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Módulo 9 \n (" + `${retorno31.length + retorno32.length + retorno33.length}` + ")", 
                    y: (((retorno31.length + retorno32.length + retorno33.length)/ totalA.length) * 100) }]} barWidth={25} 
                    labels={({ datum }) => `${(((retorno31.length + retorno32.length + retorno33.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Módulo 10 \n (" + `${retorno34.length + retorno35.length + retorno36.length}` + ")", 
                    y: (((retorno34.length + retorno35.length + retorno36.length)/ totalA.length) * 100) }]} barWidth={25} 
                    labels={({ datum }) => `${(((retorno34.length + retorno35.length + retorno36.length)/ totalA.length) * 100).toFixed(2)}%`}
                    />

                    <VictoryBar data={[{ x: "Média \n ", y: ((t1.length + t2.length)/ 10)}]} barWidth={25} 
                    labels={({ datum }) => `${((t1.length + t2.length)/ 10).toFixed(2)}%`}
                    />

                    </VictoryGroup>
                </VictoryChart>

                </View>
            </Animatable.View>
            <View style={{height: 100}}>
            </View>
            </>
            }

        </View>
    )
}