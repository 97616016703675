import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Linking} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore, orderBy} from "firebase/firestore";
import styles from './style';



export default function CefalometriaPDFReprovadosG11({ navigation }) {

    const [open, setOpen] = useState(false);
    const [openFotos, setOpenFotos] = useState(false);
    const [retorno, setRetorno] = useState([]);
    const [order, setOrder] = useState("asc")  


    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("formG11C4", "==", 'Cefalometría (pdf webceph)'), where("status", "==", 'Reprovado'), where("delivery.startTime", "!=", ''), orderBy('delivery.startTime', order));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                grupo: doc.data().ifoUsuario,
                url: doc.data().url,
                form: doc.data().FormNBHG,
                status: doc.data().status,
                data : doc.data().data
            };
            array.push(Obj)
        });

        setRetorno(array)
    }


    useEffect(() => {
        getResumos();
    }, [])

    useEffect(() => {
        getResumos();
    }, [order])


    return (
        <>
            <View style={styles.container}>

                <View style={styles.header}>
                    <View>
                        <Text style={styles.Title}>Cefalometría pdf webceph (Grupo 11)</Text>
                    </View>

                    <View style={{position: 'absolute', right: 0, marginRight: 20, marginTop: -10}}>
                        <Text style={styles.Title}>{retorno.length}</Text>
                    </View>

                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.navigate('HomeGrupo11')} >
                        <MaterialCommunityIcons
                            name="home"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>
                </View>

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("CefalometriaPDFG11")}>

                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Sem correção</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("CefalometriaPDFAprovadosG11")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Aprovados</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#fff", margin: 20 }} onPress={() => navigation.navigate("CefalometriaPDFReprovadosG11")}>
                        <Text style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>Reprovados</Text>
                    </TouchableOpacity>

                    <View style={{position: 'absolute', right: 0, marginRight: 80, marginTop: 25}}>
                        <TouchableOpacity onPress={() => {order === "asc" ? setOrder("desc") : setOrder("asc") }}>
                      <MaterialCommunityIcons
                            name={order === "asc" ? "filter-outline" : "filter"}
                            size={60}
                            color="#ffffff" />
                           
                        </TouchableOpacity>
                    </View>


                </View>

                {retorno && retorno.map((item) => {
                    return (
                        <>
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 35}}>Data: {item.data}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>

                            </View>

                                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 20, marginTop: 10 }}>
                                <TouchableOpacity style={styles.buttonView} onPress={() => {
                                        Linking.openURL(item.url);
                                        }}>
                                        <Text style={styles.textView}>Ver Resumos</Text>
                                    </TouchableOpacity>
                                </View>
                            
                        </>
                    )
                })

                }
                      
            </View>
        </>
    )

}