import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({

    Title: {
        fontSize: 35,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 20,
    },

    container: {
        flex: 1,
        zoom: 0.65,
        backgroundColor: "#0F0F0F"
    },

    menu: {
        justifyContent: 'left',
        alingnItens: 'center',
        height: 975,
        width: 300,
        borderTopRightRadius: 70,
        borderBottomRightRadius: 70,
        borderColor: '#29D8FF',
        borderStyle: 'solid',
        borderWidth: 2,
        borderLeftColor: '#055669'
    },

    header: {
        height: 115,
        backgroundColor: "#FFFFFF15",
      },

    titleMenu: {
        fontSize: 35,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 10,
        marginBottom: 25,
        marginLeft: 60,
    },

    buttomMenu: {
        alignItems: 'left',
        alignContent: 'center',
        position: 'absolute',
        marginTop: 30,
        marginLeft: 40,
    },

    MenuModulos: {
        fontSize: 50,
        textAlign: 'center',
        color: '#fff',
        fontWeight: 'bold',
    },

    MenuDescription: {
        fontSize: 20,
        textAlign: 'center',
        color: '#fff',
    },

    divImg: {
        alingnItens: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },

    img: {
        width: 900,
        height: 900,
    },

    buttomMenu2: {
        alignItems: 'right',
        alignContent: 'center',
        position: 'absolute',
        marginTop: 150,
        marginLeft: 89,
    },

    MenuModulos2: {
        fontSize: 25,
        textAlign: 'center',
        color: '#fff',
    },

    buttonApresentacao: {
        width: 200,
        height: 70,
        borderRadius: 30,
        borderColor: '#0A5A61',
        borderStyle: 'dashed',
        borderWidth: 3,
        marginLeft: 20,
        marginTop: 30,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#DDF4F6"
    },


});

export default styles