import React, { useState, useEffect, useRef} from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Alert, TextInput, Linking, ActivityIndicator} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc, Timestamp, orderBy} from "firebase/firestore";
import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";
import  * as DocumentPicker from 'expo-document-picker';
import { getAuth } from "firebase/auth";
import { useFocusEffect } from '@react-navigation/native';
import styles from './style';



export default function Testemunho_Intercept_Aprovado_Grupo12({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [email, setEmail] = useState("");

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [reload, setReload] = useState("");

    const [dataAtual, setDataAtual] = useState("")


    const Tick =  () => {
        var data = new Date();

        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        setDataAtual(dia + '/' + mes + '/' + ano)

    }

    useEffect(() => {
        getResumos();
        setReload("");
        setOpen(false);
        setOpen2(false);
        setEmail(false);
        setRetorno([])
    }, [reload])


    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'testemunho'), where("concluido", "==", "Favorito"), where("diplo", "==", "Intercept"), where("grupo", "==", "Grupo12"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                tipo: doc.data().tipo,
                midia: doc.data().midia,
                email: doc.data().email,
                arquivo: doc.data().arquivo,
                nome: doc.data().nome,
                dataAtual: doc.data().dataAtual,
                diplo: doc.data().diplo,
                grupo: doc.data().grupo,
                concluido: "Favorito"
                
            };
            array.push(Obj)
        });

 
       setRetorno(array)
    }

    

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email), where("concluido", "==", "Favorito"), where("diplo", "==", "Intercept"), where("grupo", "==", "Grupo12"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                tipo: doc.data().tipo,
                midia: doc.data().midia,
                email: doc.data().email,
                arquivo: doc.data().arquivo,
                nome: doc.data().nome,
                dataAtual: doc.data().dataAtual,
                diplo: doc.data().diplo,
                grupo: doc.data().grupo,
                concluido: "Favorito"
                
            };
            array.push(Obj)
        });

 
       setRetorno2(array)
    }


    
   
    useEffect(() => {
        getResumos();
        Tick();
    }, [])

    useEffect(() => {
        getResumos2();
    },[email])

    return (
        <>
            <View style={styles.container}>
                
                <View style={styles.header}>
                    {open !== true ?
                        <View>
                            <Text style={styles.Title}>Favoritos</Text>
                        </View>
                        :
                        <View>
                            <Text style={styles.Title}>Depoimento</Text>
                        </View>
                    }
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>
                    {open ===  false &&
                        <TouchableOpacity style={{position: "absolute", top: 0, right: 30, marginTop: 20}} onPress={() => {getResumos()}} >
                            <MaterialCommunityIcons
                                name="refresh-circle"
                                size={50}
                                color="#ffffff" />
                        </TouchableOpacity>
                    }

                </View>
                

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>

                {open !== true ?
                    <>
                    <TouchableOpacity style={styles.navDeseble} onPress={() => navigation.navigate("Testemunho_Intercept_Grupo12")}>
                        <Text style={styles.textNavDeseble}>Fila de espera</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.navDeseble} onPress={() => navigation.navigate("Testemunho_Intercept_Guardado_Grupo12")}>

                        <Text style={styles.textNavDeseble}>Recebidos</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.navEnable} onPress={() => navigation.navigate("Testemunho_Intercept_Aprovado_Grupo12")}>
                        <Text style={styles.textNavEnable}>Favoritos</Text>
                    </TouchableOpacity>
                    

                    </>
                    :
                    <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
                            <TouchableOpacity onPress={() => {setOpen(false), setEmail("")}}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}>Favoritos</Text>
                            </TouchableOpacity>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}> -{'>'} </Text>
                            <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold"}}>Depoimento Enviado</Text>
                        </View>
                    }
                </View>

                {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>
                        
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 17}}>Diplomado: {item.diplo}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                            </View>

                            <View style={styles.alinhadorVideo}> 
                                <TouchableOpacity style={styles.botaoVideo} onPress={() => {Linking.openURL(item.arquivo)}}>
                                    <Text style={styles.textBotaoVideo}>Assistir Depoimento</Text>
                                </TouchableOpacity>
                            </View>

                        </>
                    )
                    })}
                </View>
            }
            <View style={{justifyContent: "center",  alignItems: "center"}}>
            <ScrollView horizontal={true} style={{flex: 1}}>
            {open ===  false &&
                <View style={{margin: 30, justifyContent: "center", alignItems: "center"}}>
                    <View style={{flexDirection: 'row',  }}>
                        <View style={{backgroundColor: "#FAEDB5", width: 400, height: 60, margin: 5, borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Email</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Grupo</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Diplomado</Text>
                        </View>
  
                        
                        <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Abrir Testemunho</Text>
                        </View>
                    </View>

                    {retorno && retorno.map((item) => {
                    return (
                        <>
                            <View style={{flexDirection: 'row', }}>
                                <View style={{backgroundColor: "#EDEDF4", width: 400, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.nome}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.email}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{(item.grupo).replace(/Grupo(\d+)/g, "Grupo $1")}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.diplo}</Text>
                                </View>

                                    <TouchableOpacity style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 5, borderRadius: 15, justifyContent: "center", alignItems: "center"}}
                                        onPress={() => {
                                            setEmail(item.email),
                                            setOpen(true)
                                        }}>
                                        <Text style={{color: "#fff", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Abrir</Text>
                                    </TouchableOpacity>
                            </View>
                        </>
                    )
                    })}
                </View>
            }
            </ScrollView>
            </View>

            </View>
        </>
    )

}