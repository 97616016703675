import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, ScrollView, TextInput, Linking, ActivityIndicator, Modal} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc, Timestamp, orderBy} from "firebase/firestore";
import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";
import { getAuth } from "firebase/auth";
import  * as DocumentPicker from 'expo-document-picker';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import styles from './style';



export default function TrocadeHorarioDAO({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [email3, setEmail3] = useState("");
    const [cas, setCas] = useState("");
    const [diplo, setDiplo] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [reload, setReload] = useState("");
    const [email2, setEmail2] = useState("");
    const [dataAtual, setDataAtual] = useState("")
    const [nome, setNome] = useState("");
    const [dataReuniao, setDataReuniao] = useState("");
    const [dataReuniaoFormatada, setDataReuniaoFormatada] = useState("");
    const [modalVisible, setModalVisible] = useState(false);
    const [dataCasos, setDataCaso] = useState("");
    const [openPicker, setOpenPicker] = useState(false);


    const auth = getAuth();
    const user = auth.currentUser;
   
    const Tick =  () => {
        var data = new Date();

        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        setDataAtual(dia + '/' + mes + '/' + ano)
    }

    function getDataHoraFormatada() {
        const data = new Date();
        const ano = data.getFullYear();
        const mes = ('0' + (data.getMonth() + 1)).slice(-2); // Meses começam em 0
        const dia = ('0' + data.getDate()).slice(-2);
        const hora = ('0' + data.getHours()).slice(-2);
        const minuto = ('0' + data.getMinutes()).slice(-2);
      
        const horaAtual = `${ano}-${mes}-${dia}T${hora}:${minuto}`;

        setDataReuniao(horaAtual);
      }

      function formatarDataHora() {
        const data = new Date(dataReuniao);
        const ano = data.getFullYear();
        const mes = ('0' + (data.getMonth() + 1)).slice(-2);
        const dia = ('0' + data.getDate()).slice(-2);
        const hora = ('0' + data.getHours()).slice(-2);
        const minuto = ('0' + data.getMinutes()).slice(-2);
      
        const dataFormatada = `${dia}/${mes}/${ano} - ${hora}:${minuto}`;

        setDataReuniaoFormatada(dataFormatada);
      }

      useEffect(() => {
        getDataHoraFormatada();
    }, [open])

      useEffect(() => {
        formatarDataHora();
    }, [dataReuniao])


    useEffect(() => {
        getResumos();
        setReload("");
        setOpen(false);
        setOpen2(false);
        setEmail(false);
        setEmail2("");
        setRetorno([]);
        setModalVisible(false);
        setOpenPicker(false);
    }, [reload])
    

    const hideAlert = () => {
        setModalVisible(false);
    };

    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("dataReuniaoFormatada", "!=", ''));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                consulta: doc.data().ifoUsuario,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
                dataReuniaoFormatada: doc.data().dataReuniaoFormatada,
                CasoDAO: doc.data().CasoDAO,
                nomePaciente: doc.data().nomePaciente,
            };
            array.push(Obj)
        });

        setRetorno(array)
    }
    

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email), where("numeroCaso", "==", cas), where("validata", "==", dataCasos), orderBy("email"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data.seconds,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                consulta: doc.data().ifoUsuario,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
                dataReuniaoFormatada: doc.data().dataReuniaoFormatada,
                CasoDAO: doc.data().CasoDAO,
                nomePaciente: doc.data().nomePaciente,
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const getResumos3 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "CasosDAO");
        const q = query(citiesRef, where("email", "==", email), where("numeroCaso", "==", cas), where("validata", "==", dataCasos));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
                 setId(doc.id)
            
        });

    }


    useEffect(() => {
        getResumos();
        Tick();
        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail3(profile.email)
            });
        } 

    }, [])

    const getNome = async () => {
        
        const db = getFirestore();
        
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", email3));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        
            setNome(doc.data().nome);
        });
    
}

    useEffect(() => {
        getResumos2();
        getResumos3();
    },[email])

    useEffect(() => {
        getNome();
    },[email3])

    return (
        <>
            <View style={styles.container}>
                
                <View style={styles.header}>
                    {open !== true ?
                        <View>
                            <Text style={styles.Title}>Caso Clínico</Text>
                        </View>
                        :
                        <View>
                            <Text style={styles.Title}>Caso clínico do aluno</Text>
                        </View>
                    }
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>

                    <TouchableOpacity style={{position: "absolute", top: 20, right: 60}} onPress={() => {navigation.navigate("FilaProfessor")}}>
                        <Text style={{fontSize: 50, textAlign: 'center'}}>📚</Text>
                    </TouchableOpacity>

                    <View style={{position: "absolute", top: 30, right: 150}}>
                        <Text style={{fontSize: 30, textAlign: 'center', color: "#FFF", fontWeight: "bold"}}>Nº Casos: {retorno.length}</Text>
                    </View>

                </View>
                

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30, }}>

                {open !== true ?
                    <>
                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("Fila")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Aguardando validação</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("Geral")}>

                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Planilha Geral</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("ConsultaAluno")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Consulta de casos clínicos</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("FilaCasosDAO")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Fila)</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFF", margin: 20,
                    }}>
                        <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Aprovados)</Text>
                    </TouchableOpacity>
                    </>
                    :
                    <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
                            <TouchableOpacity onPress={() => {setOpen(false), setEmail(""), setCas("")}}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}>Aguardando validação</Text>
                            </TouchableOpacity>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}> -{'>'} </Text>
                            <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold"}}>Documentos Enviados</Text>
                        </View>
                    }
                </View>

                {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>

                            <Modal
                                transparent={true}
                                animationType="fade"
                                visible={modalVisible}
                                onRequestClose={hideAlert}
                            >
                                <View style={styles.modalBackground}>
                                    <View style={styles.modalContainer}>
                                        <Text style={styles.modalText}>A sessão ocorrerá em {dataReuniaoFormatada}?</Text>

                                            <View style={styles.buttonContainer}>
                                                <TouchableOpacity style={styles.modalButton} onPress={async () => {

                                                    const db = getFirestore();
                                                    if(item.status === "Esperando análisis de los profesores para dao"){
                                                
                                                    const docRef = await addDoc(collection(db, "Resumos"), {
                                                        email: item.email,
                                                        ifoUsuario: item.consulta,
                                                        Anamnesis: item.Anamnesis,
                                                        DocPaciente: item.DocPaciente,
                                                        CefalometriaPaciente: item.CefalometriaPaciente,
                                                        data: Timestamp.fromDate(new Date()),
                                                        Envio: item.Envio,
                                                        numeroCaso: item.numeroCaso,
                                                        status: item.status, 
                                                        validata: item.validata,
                                                        initData: item.initData,
                                                        Prof: item.Prof,
                                                        Planej: item.Planej,
                                                        face: item.face,
                                                        tipo: item.tipo,
                                                        Motivo: item.Motivo,
                                                        Arquivo: item.Arquivo,
                                                        dataReuniaoFormatada: dataReuniaoFormatada,
                                                        CasoDAO: item.CasoDAO,
                                                        nomePaciente: item.nomePaciente,
                                                    });
                                                } else if(item.status === "Finalizado"){
                                                    const db = getFirestore();
                                                    const docRef = await addDoc(collection(db, "Resumos"), {
                                                        email: item.email,
                                                        ifoUsuario: item.consulta,
                                                        Anamnesis: item.Anamnesis,
                                                        DocPaciente: item.DocPaciente,
                                                        CefalometriaPaciente: item.CefalometriaPaciente,
                                                        data: Timestamp.fromDate(new Date()),
                                                        Envio: item.Envio,
                                                        numeroCaso: item.numeroCaso,
                                                        status: item.status, 
                                                        validata: item.validata,
                                                        initData: item.initData,
                                                        Prof: item.Prof,
                                                        Planej: item.Planej,
                                                        face: item.face,
                                                        tipo: item.tipo,
                                                        Motivo: item.Motivo,
                                                        Arquivo: item.Arquivo,
                                                        dataReuniaoFormatada: dataReuniaoFormatada,
                                                        CasoDAO: item.CasoDAO,
                                                        nomePaciente: item.nomePaciente,
                                                    });

                                                    const docRef2 = await addDoc(collection(db, "CasosDAO"), {
                                                        email: item.email,
                                                        ifoUsuario: item.consulta,
                                                        Anamnesis: item.Anamnesis,
                                                        DocPaciente: item.DocPaciente,
                                                        CefalometriaPaciente: item.CefalometriaPaciente,
                                                        data: Timestamp.fromDate(new Date()),
                                                        Envio: item.Envio,
                                                        numeroCaso: item.numeroCaso,
                                                        status: item.status, 
                                                        validata: item.validata,
                                                        initData: item.initData,
                                                        Prof: item.Prof,
                                                        Planej: item.Planej,
                                                        face: item.face,
                                                        tipo: item.tipo,
                                                        Motivo: item.Motivo,
                                                        Arquivo: item.Arquivo,
                                                        dataReuniaoFormatada: dataReuniaoFormatada,
                                                        CasoDAO: item.CasoDAO,
                                                        nomePaciente: item.nomePaciente,
                                                    });
                                                }
                                                    
                                                    await deleteDoc(doc(db, "Resumos", item.id));
                                                    await deleteDoc(doc(db, "CasosDAO", id));
                                                    alert("Data Atualizada", "...")
                                                
                                                    setReload("Recarrega")      
                                                }}>
                                                    <Text style={styles.buttonText}>Sim</Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity onPress={hideAlert} style={styles.modalButton2}>
                                                    <Text style={styles.buttonText}>Não</Text>
                                                </TouchableOpacity>
                                            </View>
                                    </View>
                                </View>
                            </Modal>
                        
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 17}}>Nº Caso Clínico: {item.numeroCaso}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnEmail2}>{item.Envio}        {item.diplo}</Text>
                            </View>

                            <View style={styles.viewInicial}>
                                {item.Anamnesis !== "" &&
                                    <TouchableOpacity style={styles.buttonView1} onPress={() => {
                                            Linking.openURL(item.Anamnesis);
                                            }}>
                                            <Text style={styles.textView}>📄 Guía de Anamnesis</Text>
                                    </TouchableOpacity>
                                }

                                <TouchableOpacity style={styles.buttonView2} onPress={() => {
                                        Linking.openURL(item.DocPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Documentação do Paciente</Text>
                                </TouchableOpacity>


                                {item.CefalometriaPaciente !== "" &&
                                    <TouchableOpacity style={styles.buttonView3} onPress={() => {
                                            Linking.openURL(item.CefalometriaPaciente);
                                            }}>
                                            <Text style={styles.textView}>📄 Cefalometría</Text>
                                    </TouchableOpacity>
                                }
                            </View>

                                <View style={styles.viewInicial}>
                                    {openPicker === false &&
                                        <TouchableOpacity style={styles.buttonOk} onPress={() => {setOpenPicker(true);}}>
                                                <MaterialCommunityIcons
                                                    name="calendar-clock"
                                                    size={25}
                                                    color="#000000"
                                                    style={{ alignSelf: 'center' }}
                                                />
                                                <Text style={{ textAlign: 'center', fontSize: 15, color: '#000000', }}>Trocar Data</Text>
                                        </TouchableOpacity>
                                    }
                                </View>

                                {openPicker === true &&
                                    <>
                                        <View style={styles.viewInicial}>
                                            <View style={styles.viewInicialPicker}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DateTimePicker']}>
                                                        <DateTimePicker
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}
                                                            onChange={(text) => setDataReuniao(text)}
                                                            defaultValue={dayjs(dataReuniao)}
                                                        />
                                                                
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </View>
                                        </View>

                                        <View style={styles.viewInicial}>

                                        {open2 === false &&
                                            <TouchableOpacity style={styles.buttonOk} onPress={() => {setModalVisible(true);}}>

                                                <MaterialCommunityIcons
                                                    name="calendar-clock"
                                                    size={25}
                                                    color="#000000"
                                                    style={{ alignSelf: 'center' }}
                                                />
                                                <Text style={{ textAlign: 'center', fontSize: 15, color: '#000000', }}>Confirmar</Text>
                                            </TouchableOpacity>
                                        }
                                        </View>
                                    </>    
                                }

                        </>
                    )
                    })}
                </View>
            }

            <View style={{justifyContent: "center",  alignItems: "center"}}>          
            <ScrollView horizontal={true} style={{flex: 1}}>
            {open ===  false &&
                <View style={{margin: 30,}}>
                    <View style={{flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                    <View style={{backgroundColor: "#FAEDB5", width: 400, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Email</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Diplomado</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>No. Caso Clínico</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 250, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Agendado Para:</Text>
                        </View>
                        
                        <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Abrir Documentos</Text>
                        </View>
                        </View>

                        {retorno && retorno.map((item) => {
                        return (
                            <>
                                <View style={{flexDirection: 'row', }}>
                                    <View style={{backgroundColor: "#EDEDF4", width: 400, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.nome}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.email}</Text>
                                    </View>
 
                                    <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.diplo}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.numeroCaso}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 250, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.dataReuniaoFormatada}</Text>
                                    </View>

                                    
                                    <View style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>

                                        <TouchableOpacity onPress={() => {
                                            setEmail(item.email),
                                            setCas(item.numeroCaso),
                                            setDiplo(item.diplo),
                                            setOpen(true),
                                            setDataCaso(item.validata)
                                        }}>
                                            <Text style={{color: "#fff", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Ver Caso Clínico</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            </>
                        )
                        })}
                </View>
            }
            </ScrollView>
            </View>  

            </View>
        </>
    )

}