import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  Title: {
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    marginTop: 20,
  },

  container: {
    flex: 1,
    zoom: 0.7,
    backgroundColor: "#0F0F0F",
  },

  header: {
    height: 115,
    backgroundColor: "#FFFFFF15",
  },

  titleMenu: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#fff",
    marginTop: 10,
    marginBottom: 25,
    marginLeft: 60,
  },

  buttomMenu: {
    alignItems: "left",
    alignContent: "center",
    position: "absolute",
    marginTop: 30,
    marginLeft: 40,
  },

  MenuModulos: {
    fontSize: 20,
    textAlign: "center",
    color: "#fff",
  },

  input: {
    alignItems: "center",
    width: 500,
    marginTop: 30,
    padding: 10,
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: "#fff",
    color: "#fff",
    fontSize: 17,
    fontWeight: "bold",
  },

  input2: {
    alignItems: "center",
    width: 400,
    padding: 10,
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: "#fff",
    color: "#fff",
    fontSize: 17,
    fontWeight: "bold",
    marginLeft: 30,
  },

  picker: {
    borderWidth: 0,
    borderRadius: 15,
    padding: 15,
    fontSize: 17,
    backgroundColor: "#E8E8E8",
    width: 455,
    height: 60,
    marginBottom: 30,
  },

  bottom: {
    backgroundColor: "#5BC51B",
    width: 250,
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
  },

  bottom2: {
    backgroundColor: "#5BC51B",
    width: 250,
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 50,
    marginLeft: 30
  },

  textBottom: {
    fontSize: 17,
    fontWeight: "700",
    textTransform: "uppercase",
  },

  img: {
    width: 300,
    height: 300,
    borderRadius: 30,
  },

  buttomEnviar: {
    width: 350,
    height: 60,
    justifyContent: "center",
    borderColor: "#09819C",
    borderStyle: "solid",
    borderRadius: 20,
    alingItens: "center",
    alignContent: "center",
    alignSelf: "center",
    marginTop: 100,
    marginBottom: 80,
    backgroundColor: "#09819C",
    borderWidth: 1,
  },

  modalBackground: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },

  modalContainer: {
    width: 300,
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    alignItems: "center",
  },

  modalText: {
    marginBottom: 20,
  },

  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  modalButton: {
    backgroundColor: "#13EBB1",
    padding: 10,
    borderRadius: 5,
    margin: 5,
  },

  modalButton2: {
    backgroundColor: "#EA0038",
    padding: 10,
    borderRadius: 5,
    margin: 5,
  }

});

export default styles;
