import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, TextInput, Modal } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc } from "firebase/firestore";
import axios from 'axios';
import styles from './style';


export default function Modulo4G12({ navigation }) {

    const [open, setOpen] = useState(false);
    const [openFotos, setOpenFotos] = useState(false);
    const [emailAluno, setEmailAluno] = useState("");
    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [retorno3, setRetorno3] = useState([]);
    const [email, setEmail] = useState("");
    const [reload, setReload] = useState("");
    const [dataAtual, setDataAtual] = useState("");
    const [modalVisible, setModalVisible] = useState(false);


    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nomeForm", "==", 'Módulo 4'), where("grupo", "==", "Grupo12"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                urlImg: doc.data().urlImg,
                form: doc.data().nomeForm,
                status: doc.data().status,
                data : doc.data().data
            };
            array.push(Obj)
        });

        setRetorno(array)
    }

    const Tick =  () => {
        var data = new Date();

        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        setDataAtual(dia + '/' + mes + '/' + ano)

    }

    async function CountResumos(email) {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email), where("status", "==", "ok"));
        let array = [];
    
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
    
            const Obj = {
              
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                form: doc.data().nomeForm,
    
            };
            array.push(Obj)
        });
    
        const db2 = getFirestore();
        const citiesRef2 = collection(db2, "Perola");
        const q2 = query(citiesRef2, where("tipo", "==", "Perola"));
        let array2 = [];
    
        const querySnapshot2 = await getDocs(q2);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
    
            const Obj = {
              tipo: doc.data().tipo
            };
            array2.push(Obj)
        });
    
        setRetorno2(array)
        setRetorno3(array2)
    
    }
    
    useEffect(() => {
        EnviaPlanilha()
    }, [retorno2])
    
    async function EnviaPlanilha() {
        if(retorno2.length >= 7) {
            axios.post("https://hook.us1.make.com/64wb45b9l2aw2hfpl6qz77cvpxge4wwf",{
                nome: retorno[0].nome,
                email: retorno[0].email,
                grupo: retorno[0].grupo,
            }).then(function (response) {
                console.log(response);
            }).catch(function (error) {
                console.error(error);
            });
    
            if(retorno3.length === 0) {
    
                const db = getFirestore();
                const docRef2 = await addDoc(collection(db, "Perola"), {
                    tipo: "Perola",
                });
            }
        }
    }

    const hideAlert = () => {
        setModalVisible(false);
    };

    useEffect(() => {
        getResumos();
        setReload("");
        setOpen(false);
        setOpenFotos(false);
        setEmailAluno("");
        Tick();
        setModalVisible(false);
    }, [reload])


    return (
        <>
            <View style={styles.container}>

                <View style={styles.header}>
                    <View>
                        <Text style={styles.Title}>Módulo 4 (Grupo 12)</Text>
                    </View>

                    <View style={{position: 'absolute', right: 0, marginRight: 20, marginTop: -10}}>
                        <Text style={styles.Title}>{retorno.length}</Text>
                    </View>
                    

                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.navigate('HomeGrupo12')} >
                        <MaterialCommunityIcons
                            name="home"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>

                </View>

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#fff", margin: 20,
                    }} >

                        <Text style={{ color: "#fff", fontSize: 25, fontWeight: "bold" }}>Sem correção</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("Modulo4AprovadosG12")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Aprovados</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("Modulo4ReprovadosG12")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Reprovados</Text>
                    </TouchableOpacity>

                </View>

                <View style={{position: 'absolute', right: 0, top: 0, marginTop: 130, marginRight: 30}}>
                    <TouchableOpacity onPress={async () => navigation.navigate('Modulo4FavoritosG12')}>
                        <MaterialCommunityIcons
                        name="star"
                        size={65}
                        color="#F4E428"
                        style={{ alignSelf: 'center' }}
                        />
                    </TouchableOpacity>
                </View>

                {retorno && retorno.map((item) => {
                    return (
                        <>
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 35}}>Data: {item.data}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnGrupo}>{item.status}</Text>
                            </View>

                            {openFotos === false &&
                                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 20, marginTop: 10 }}>
                                    <TouchableOpacity style={styles.buttonView} onPress={() => {setOpenFotos(true), setEmailAluno(item.email)}}>
                                        <Text style={styles.textView}>Ver Resumos</Text>
                                    </TouchableOpacity>
                                </View>
                            }

                            {openFotos === true && item.email === emailAluno &&
                                <View style={styles.divImg}>

                                    {openFotos === true &&
                                        <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 20, marginTop: 20 }}>
                                            <TouchableOpacity style={styles.buttonView} onPress={() => {
                                                setOpenFotos(false)
                                                setOpen(false)
                                            }}>
                                                <Text style={styles.textView}>Fechar</Text>
                                            </TouchableOpacity>
                                        </View>
                                    }

                                    <ScrollView
                                        horizontal={true}
                                    >
                                        {item.urlImg[0] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[0],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 1</Text>
                                            </View>

                                        }

                                        {item.urlImg[1] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[1],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 2</Text>
                                            </View>
                                        }

                                        {item.urlImg[2] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[2],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 3</Text>
                                            </View>

                                        }

                                        {item.urlImg[3] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[3],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 4</Text>
                                            </View>
                                        }

                                        {item.urlImg[4] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[4],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 5</Text>
                                            </View>
                                        }

                                        {item.urlImg[5] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[5],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 6</Text>
                                            </View>
                                        }

                                        {item.urlImg[6] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[6],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 7</Text>
                                            </View>
                                        }

                                        {item.urlImg[7] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[7],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 8</Text>
                                            </View>
                                        }

                                        {item.urlImg[8] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[8],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 9</Text>
                                            </View>
                                        }

                                        {item.urlImg[9] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[9],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 10</Text>
                                            </View>
                                        }

                                        {item.urlImg[10] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[10],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 11</Text>
                                            </View>
                                        }

                                        {item.urlImg[11] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[11],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 12</Text>
                                            </View>
                                        }

                                        {item.urlImg[12] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[12],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 13</Text>
                                            </View>
                                        }

                                        {item.urlImg[13] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[13],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 14</Text>
                                            </View>
                                        }

                                        {item.urlImg[14] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[14],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 15</Text>
                                            </View>
                                        }

                                        {item.urlImg[15] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[15],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 16</Text>
                                            </View>
                                        }

                                        {item.urlImg[16] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[16],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 17</Text>
                                            </View>
                                        }

                                        {item.urlImg[17] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[17],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 18</Text>
                                            </View>
                                        }

                                        {item.urlImg[18] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[18],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 19</Text>
                                            </View>
                                        }

                                        {item.urlImg[19] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[19],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 20</Text>
                                            </View>
                                        }

                                        {item.urlImg[20] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[20],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 21</Text>
                                            </View>
                                        }

                                        {item.urlImg[21] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[21],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 22</Text>
                                            </View>
                                        }

                                        {item.urlImg[22] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[22],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 23</Text>
                                            </View>
                                        }

                                        {item.urlImg[23] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[23],
                                                }} />
                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 24</Text>
                                            </View>
                                        }

                                        {item.urlImg[24] !== "" &&
                                            <View>
                                                <Image style={styles.img} source={{
                                                    uri: item.urlImg[24],
                                                }} />

                                                <Text style={{ textAlign: 'center', color: '#FFF', alignContent: 'center', alignItems: 'center', alignSelf: 'center', fontSize: 30, marginBottom: 20 }}>Pagina: 25</Text>
                                            </View>
                                        }

                                    </ScrollView>
                                </View>
                            }


                            {openFotos === true && item.email === emailAluno &&
                                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 30, marginTop: 20 }}>

                                    <TouchableOpacity style={styles.buttonProblema} onPress={() => { open === false ? setOpen(true) : setOpen(false)}}>

                                    <MaterialCommunityIcons
                                        name="close"
                                        size={25}
                                        color="#ffffff"
                                        style={{ alignSelf: 'center' }}
                                    />

                                    <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}> { open === false ? "Reprovar" : "Cancelar" }</Text>

                                    </TouchableOpacity>


                                    {open === false &&
                                    <>
                                    <Modal
                                        transparent={true}
                                        animationType="slide"
                                        visible={modalVisible}
                                        onRequestClose={hideAlert}
                                    >
                                        <View style={styles.modalBackground}>
                                        <View style={styles.modalContainer}>
                                            <Text style={styles.modalText}>Deseja aprovar esse resumo?</Text>
                                            <View style={styles.buttonContainer}>
                                            <TouchableOpacity style={styles.modalButton} onPress={async () => {

                                                const db = getFirestore();
                                                const docRef = await addDoc(collection(db, "Resumos"), {
                                                    to: [item.email],
                                                    message: {
                                                        subject: 'G12 - Módulo 4 - Resumen aprobado',
                                                        html: '<p>Hola Doc. ' + (item.nome).split(' ')[0] + ', ¡saludos! </p><p>Tu resumen del ' + item.form + ' fue aprobado.</p><p>Seguimos en contacto.</p><p>Equipo de Soporte Profª Rayane Pinto<br/>Odontología Sin Fronteras Cursos e Treinamentos LTDA</p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                    },
                                                    nome: item.nome,
                                                    formG121: item.form,
                                                    email: item.email,
                                                    ifoUsuario: item.grupo,
                                                    urlImg: item.urlImg,
                                                    status: "ok",
                                                    data: dataAtual
                                                });

                                                const docRef2 = await addDoc(collection(db, "Concluido"), {
                                                    nomeForm: "Módulo 4",
                                                    email: item.email,
                                                    status: "ok",
                                                    });

                                                    axios.post(
                                                        "https://hook.us1.make.com/we3ra7fz15dxc1nffh4v28i1mrvdgzjg",
                                                        {
                                                            email: item.email,
                                                            status: "✅",
                                                        }
                                                    )
                                                    .then(function (response) {
                                                        console.log(response);
                                                    })
                                                    .catch(function (error) {
                                                        console.error(error);
                                                    });

                                                CountResumos(item.email);

                                                await deleteDoc(doc(db, "Resumos", item.id));
                                                alert("Email de aprovação Enviado para o aluno", "...")

                                                setReload("Recarrega")                                               
                                            }}>
                                                <Text style={styles.buttonText}>Sim</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={hideAlert} style={styles.modalButton2}>
                                                <Text style={styles.buttonText}>Não</Text>
                                            </TouchableOpacity>
                                            </View>
                                        </View>
                                        </View>
                                    </Modal>
                                    <TouchableOpacity style={styles.buttonOk} onPress={() => {setModalVisible(true)}}>

                                        <MaterialCommunityIcons
                                            name="check-bold"
                                            size={25}
                                            color="#ffffff"
                                            style={{ alignSelf: 'center' }}
                                        />
                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Aprovar</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={styles.buttonOk2} onPress={async () => {

                                        const db = getFirestore();
                                        const docRef = await addDoc(collection(db, "Resumos"), {
                                            to: [item.email],
                                            message: {
                                                subject: 'G12 - Módulo 4 - Resumen aprobado',
                                                html: '<p>Hola Doc. ' + (item.nome).split(' ')[0] + ', ¡saludos! </p><p>Tu resumen del ' + item.form + ' fue aprobado.</p><p>Seguimos en contacto.</p><p>Equipo de Soporte Profª Rayane Pinto<br/>Odontología Sin Fronteras Cursos e Treinamentos LTDA</p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                            },
                                            nome: item.nome,
                                            formG121: item.form,
                                            email: item.email,
                                            ifoUsuario: item.grupo,
                                            urlImg: item.urlImg,
                                            status: "ok",
                                            data: dataAtual
                                        });

                                        const docRef2 = await addDoc(collection(db, "Concluido"), {
                                            nomeForm: "Módulo 4",
                                            email: item.email,
                                            status: "ok",
                                            });

                                        const docRef3 = await addDoc(collection(db, "ResumosFavoritos"), {
                                                nome: item.nome,
                                                form: item.form,
                                                email: item.email,
                                                ifoUsuario: item.grupo,
                                                urlImg: item.urlImg,
                                                status: "ok",
                                                data: dataAtual
                                         });    

                                            axios.post(
                                                "https://hook.us1.make.com/we3ra7fz15dxc1nffh4v28i1mrvdgzjg",
                                                {
                                                    email: item.email,
                                                    status: "✅",
                                                }
                                            )
                                            .then(function (response) {
                                                console.log(response);
                                            })
                                            .catch(function (error) {
                                                console.error(error);
                                            });

                                        CountResumos(item.email);

                                        await deleteDoc(doc(db, "Resumos", item.id));
                                        alert("Email de aprovação Enviado para o aluno", "...")

                                        setReload("Recarrega")
                                        }}>

                                        <MaterialCommunityIcons
                                            name="star"
                                            size={25}
                                            color="#000"
                                            style={{ alignSelf: 'center' }}
                                        />
                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#000', }}>Aprovar e Favoritar</Text>
                                        </TouchableOpacity>
                                    </>
                                    }

                                </View>
                            }

                            <View style={{ justifyContent: 'center', alignSelf: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 100 }}>
                                {open === true &&

                                    <TextInput style={{
                                        alignItems: 'center', width: 600, marginTop: 10, padding: 30,
                                        height: 150, marginBottom: 10,
                                        borderWidth: 1, borderColor: "#000000", color: "#000000", backgroundColor: '#fff',
                                        borderRadius: 30,
                                    }}
                                        placeholder="Motivo de reprovação..."
                                        placeholderTextColor="#000000"
                                        type="text"
                                        multiline = {true}
                                        numberOfLines = {4}
                                        onChangeText={(text) => setEmail(text)}
                                        value={email} />
                                }

                                {open === true && email === "" &&

                                    <TouchableOpacity style={{
                                        width: 200,
                                        height: 50,
                                        backgroundColor: '#FF6553',
                                        justifyContent: 'center',
                                        borderRadius: 30,
                                    }}>

                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Enviar</Text>
                                    </TouchableOpacity>

                                }
                                {open === true && email !== "" &&

                                    <TouchableOpacity style={styles.buttonProblema2} onPress={async () => {

                                        const db = getFirestore();
                                        const docRef = await addDoc(collection(db, "Resumos"), {
                                            to: [item.email],
                                            message: {
                                                subject: 'G12 - Módulo 4 - Resumen desaprobado',
                                                html: '<p>Hola Doc. ' + (item.nome).split(' ')[0] + ', ¡saludos! </p><p> Debes enviar nuevamente el resumen del ' + item.form + '.<p>No fue aceptado porque: ' + email + '</p></p><p>Seguimos en contacto.</p><p>Equipo de Soporte Profª Rayane Pinto<br/>Odontología Sin Fronteras Cursos e Treinamentos LTDA</p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                            },
                                            nome: item.nome,
                                            formG122: item.form,
                                            email: item.email,
                                            ifoUsuario: item.grupo,
                                            urlImg: item.urlImg,
                                            status: "Reprovado",
                                            data: dataAtual
                                        });

                                        await deleteDoc(doc(db, "Resumos", item.id));
                                        setReload("Recarrega")
                                        alert("Email de reprovação Enviado para o aluno", "...")
                                        

                                    }}>
                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Enviar</Text>
                                    </TouchableOpacity>
                                }

                            </View>

                        </>
                    )
                })

                }

            </View>
        </>
    )

}