import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, ScrollView } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { VictoryChart, VictoryGroup, VictoryBar, VictoryTheme, VictoryLabel} from 'victory';
import styles from './style';
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable'

export default function Relatorio({ navigation }) {

    const [open, setOpen] = useState(false)

    const [retorno15, setRetorno15] = useState([])
    const [retorno16, setRetorno16] = useState([])

    const [retorno19, setRetorno19] = useState([])
    const [retorno20, setRetorno20] = useState([])

    const [retorno21, setRetorno21] = useState([])
    const [retorno22, setRetorno22] = useState([])

    const [apresentacao, setApresentacao] = useState(false)

    const [arrayColor, setArrayColor] = useState([])
    const [cor, setCor] = useState("")


    

    useEffect(() => {
      if(apresentacao === true){
        setArrayColor(["#7AD8E0","#C98AFA","#FAA9CB", "#8C9EFA", "#FFA268"])
        setCor("#FFF")
      }else{
        setArrayColor(["#17D2E3","#8500FA","#FA0CD8", "#0C0FFA", "#FF7D1A"])
        setCor("#FFF")
      }
    },[apresentacao])


    const getResumos15 = async () => {

      const db = getFirestore();
      const citiesRef = collection(db, "Resumos");
      const q = query(citiesRef, where("grupo", "==", "Grupo10"));
      let array = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());

          const Obj = {
              id: doc.id,
              email: doc.data().email,
              nome: doc.data().ifoUsuario.nome,
              grupo: doc.data().ifoUsuario.grupo,
              urlImg: doc.data().urlImg,
              form: doc.data().nomeForm,
              status: doc.data().status
          };
          array.push(Obj)
      });

      setRetorno15(array)
  }

  const getResumos16 = async () => {

      const db = getFirestore();
      const citiesRef = collection(db, "Resumos");
      const q = query(citiesRef, where("ifoUsuario", "==", "Grupo10"));
      let array = [];

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());

          const Obj = {
              id: doc.id,
              email: doc.data().email,
              nome: doc.data().nome,
              grupo: doc.data().ifoUsuario,
              urlImg: doc.data().urlImg,
              status: doc.data().status
          };
          array.push(Obj)
      });

      setRetorno16(array)
  }

  const getResumos19 = async () => {

    const db = getFirestore();
    const citiesRef = collection(db, "Resumos");
    const q = query(citiesRef, where("grupo", "==", "Grupo11"));
    let array = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

        const Obj = {
            id: doc.id,
            email: doc.data().email,
            nome: doc.data().ifoUsuario.nome,
            grupo: doc.data().ifoUsuario.grupo,
            urlImg: doc.data().urlImg,
            form: doc.data().nomeForm,
            status: doc.data().status
        };
        array.push(Obj)
    });

    setRetorno19(array)
}

const getResumos20 = async () => {

    const db = getFirestore();
    const citiesRef = collection(db, "Resumos");
    const q = query(citiesRef, where("ifoUsuario", "==", "Grupo11"));
    let array = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

        const Obj = {
            id: doc.id,
            email: doc.data().email,
            nome: doc.data().nome,
            grupo: doc.data().ifoUsuario,
            urlImg: doc.data().urlImg,
            status: doc.data().status
        };
        array.push(Obj)
    });

    setRetorno20(array)
}

const getResumos21 = async () => {

  const db = getFirestore();
  const citiesRef = collection(db, "Resumos");
  const q = query(citiesRef, where("grupo", "==", "Grupo12"));
  let array = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());

      const Obj = {
          id: doc.id,
          email: doc.data().email,
          nome: doc.data().ifoUsuario.nome,
          grupo: doc.data().ifoUsuario.grupo,
          urlImg: doc.data().urlImg,
          form: doc.data().nomeForm,
          status: doc.data().status
      };
      array.push(Obj)
  });

  setRetorno21(array)
}

const getResumos22 = async () => {

  const db = getFirestore();
  const citiesRef = collection(db, "Resumos");
  const q = query(citiesRef, where("ifoUsuario", "==", "Grupo12"));
  let array = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());

      const Obj = {
          id: doc.id,
          email: doc.data().email,
          nome: doc.data().nome,
          grupo: doc.data().ifoUsuario,
          urlImg: doc.data().urlImg,
          status: doc.data().status
      };
      array.push(Obj)
  });

  setRetorno22(array)
}
  

const getTotal8 = async () => {

  const db = getFirestore();
  const citiesRef = collection(db, "Usuarios");
  const q = query(citiesRef, where("grupo", "==", 'Grupo10'));
  let array = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());

      const Obj = {
          id: doc.id,
          email: doc.data().email,
          nome: doc.data().nome,
          grupo: doc.data().grupo,
      };
      array.push(Obj)
  });

  setGr10(array)
}

const getTotal9 = async () => {

  const db = getFirestore();
  const citiesRef = collection(db, "Usuarios");
  const q = query(citiesRef, where("grupo", "==", 'Grupo11'));
  let array = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());

      const Obj = {
          id: doc.id,
          email: doc.data().email,
          nome: doc.data().nome,
          grupo: doc.data().grupo,
      };
      array.push(Obj)
  });

  setGr11(array)
}


const getTotal10 = async () => {

  const db = getFirestore();
  const citiesRef = collection(db, "Usuarios");
  const q = query(citiesRef, where("grupo", "==", 'Grupo12'));
  let array = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());

      const Obj = {
          id: doc.id,
          email: doc.data().email,
          nome: doc.data().nome,
          grupo: doc.data().grupo,
      };
      array.push(Obj)
  });

  setGr12(array)
}
    useEffect(() => {
        getResumos15();
        getResumos16();
        getResumos19();
        getResumos20();
        getResumos21();
        getResumos22();
        getTotal8();
        getTotal9();
        getTotal10();
    }, [])

    useEffect(() => {
      getTotal8();
      getTotal9();
      getTotal10();
    },[retorno15, retorno16, retorno19, retorno20,retorno21, retorno22])
    
    useFocusEffect(
        React.useCallback(() => {
            setOpen(false);
        }, [])
      );

    return (

        <View style={styles.container}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>{apresentacao === false ? "Relatório" : "" }</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff" />
                </TouchableOpacity>
                
            </View>

            {open !== true &&
            <>
            <Text style={{fontSize: 50, color: "#fff", textAlign: "center", fontWeight: "bold", marginTop: 20 }}>Relatório Geral</Text>

            <Animatable.View  animation="fadeInDownBig" style={{ alignContent: 'center', alignSelf: "center" , justifyContent: 'center',  borderRadius: 20, marginTop: 30, backgroundColor: cor}}>
            
            {apresentacao === false &&
              <View>
                <TouchableOpacity style={styles.buttonApresentacao} onPress={() => setApresentacao(true)}>
                  <Text style={{color: "#000", fontSize: 15, textAlign: "center", fontWeight: 700}}>Ativar modo de Apresentação</Text>
                </TouchableOpacity>
              </View>
            }

            {apresentacao === true &&
              <View>
                <TouchableOpacity style={styles.buttonApresentacao} onPress={() => setApresentacao(false)}> 
                  <Text style={{color: "#000", fontSize: 15, textAlign: "center", margin: 10, fontWeight: 700}}>Desativar modo de Apresentação</Text>
                </TouchableOpacity>
              </View>
            }

              <Text style={{fontSize: 30, color: "#000", textAlign: "center", marginTop: 30}}>Gráfico Geral dos Grupos</Text>
             
              <View style={{flexDirection: "row", width: 1700, height: 700}}>
                  <Text style={{fontSize: 30, marginTop: 300, marginLeft: 30}}>% de Média de entregas por grupo </Text>

                  <VictoryChart
                  theme={VictoryTheme.material}
                  width={800}
                  >
                  <VictoryGroup
                  offset={2}
                  colorScale={arrayColor}
                  style={{color: cor}}
                  >

                      <VictoryBar data={[{ x: "G10 (" + `${retorno15.length + retorno16.length}` + ")",
                      y: (((retorno15.length + retorno16.length)/ 10))}]} barWidth={25} 
                      labels={({ datum }) => `${(((retorno15.length + retorno16.length)/10) ).toFixed(2)}%`}

                      events={[{
                          target: "data",
                          eventHandlers: {
                            onClick: () => {
                              return [
                                {
                                  target: "data",
                                  mutation: (props) => {
                                    const fill = props.style && props.style.fill;
                                    return fill === "#17D2E3" ? null : { style: { fill: "#17D2E3" } };
                                  }
                                }
                              ];
                            }
                          }
                        }]}
                      />

                    <VictoryBar data={[{ x: "G11 (" + `${retorno19.length + retorno20.length }` + ")",
                      y: (((retorno19.length + retorno20.length)/ 10) )}]} barWidth={25} 
                      labels={({ datum }) => `${(((retorno19.length + retorno20.length)/10)).toFixed(2)}%`}
                      events={[{
                          target: "data",
                          eventHandlers: {
                            onClick: () => {
                              return [
                                {
                                  target: "data",
                                  mutation: (props) => {
                                    const fill = props.style && props.style.fill;
                                    return fill === "#8500FA" ? null : { style: { fill: "#8500FA" } };
                                  }
                                }
                              ];
                            }
                          }
                        }]}
                      />

                      <VictoryBar data={[{ x: "G12 (" + `${retorno21.length + retorno22.length }` + ")",
                      y: (((retorno21.length + retorno22.length)/ 10) )}]} barWidth={25} 
                      labels={({ datum }) => `${(((retorno21.length + retorno22.length)/10)).toFixed(2)}%`}
                      events={[{
                          target: "data",
                          eventHandlers: {
                            onClick: () => {
                              return [
                                {
                                  target: "data",
                                  mutation: (props) => {
                                    const fill = props.style && props.style.fill;
                                    return fill === "#FA0CD8" ? null : { style: { fill: "#FA0CD8" } };
                                  }
                                }
                              ];
                            }
                          }
                        }]}
                      />

                      </VictoryGroup>
                  </VictoryChart>

                  </View>
              </Animatable.View>

              <View style={{height: 100}}>
              </View>
              </>
              }

        </View>
    )
}
