import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({

    container: {
        flex: 1,
        zoom: 0.75,
        backgroundColor: "#0F0F0F"
    },

    title: {
        fontSize: 40,
        marginBottom: 60,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 200,
    },

    title2: {
        fontSize: 40,
        marginBottom: 60,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 10,
    },
    form: {
        width: 300,
        marginTop: 40,
        padding: 10,
        height: 50,
        borderBottomWidth: 1,
        borderBottomColor: "#fff",
        marginLeft: "auto",
        marginRight: "auto",
        alingnItens: 'center',
        justifyContent: 'center',
    },
    buttonLogin: {
        width: 200,
        height: 50,
        justifyContent: 'center',
        backgroundColor: '#FFF',
        borderRadius: 50,
        marginTop: 70,
        marginBottom: 20,
    },

    textButtonLogin: {
        color: "#000",
        textAlign: 'center',
        fontSize: 17
    },
    contentAlert: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

    },
    warningAlert: {
        paddingLeft: 10,
        color: "#FFE500",
        fontSize: 16,

    },
    registation: {
        marginTop: 20,
        color: '#4d5156',
        textAlign: 'center',

    },

    linkSubscribe: {
        marginTop: 45,
        color: '#fff',
        fontSize: 16,
        textAlign: 'center',
        marginTop: 20,
    },

    Title: {
        fontSize: 35,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        marginTop: 20,
    },

    menu: {
        alingnItens: 'center',
        height: 975,
        width: 300,
        borderTopRightRadius: 70,
        borderBottomRightRadius: 70,
        borderColor: '#000',
        borderStyle: 'solid',
        borderWidth: 2,
        borderLeftColor: '#000'
    },

    header: {
        height: 115,
        backgroundColor: 'tranparent'
    },

    titleMenu: {
        fontSize: 35,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 10,
        marginBottom: 25,
        marginLeft: 25,
    },

    buttomMenu: {
        alignItems: 'left',
        alignContent: 'center',
        position: 'absolute',
        marginTop: 10,
        marginLeft: 40,
    },

    MenuModulos: {
        fontSize: 25,
        textAlign: 'center',
        color: '#000',
        marginTop: 20
    },

    MenuDescription: {
        fontSize: 20,
        textAlign: 'center',
        color: '#fff',
    },

    divImg: {
        alingnItens: 'center',
        alignContent: 'center',
        alignSelf: 'center',
    },

    img: {
        width: 700,
        height: 700,
    },

    buttomMenu2: {
        alignItems: 'right',
        alignContent: 'center',
        position: 'absolute',
        marginTop: 150,
        marginLeft: 89,
    },

    MenuModulos2: {
        fontSize: 25,
        textAlign: 'center',
        color: '#fff',
    },
});

export default styles