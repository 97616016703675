import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

//Paginas Principais
import Login from "./src/Pages/Login/Login";
import Home from "./src/Pages/Home/Home";
import Trocadesenha from "./src/Pages/Trocadesenha";

//Relatorio
import RelatorioG10 from "./src/Pages/Relatorio/Relatoriog10";
import RelatorioG11 from "./src/Pages/Relatorio/Relatoriog11";
import RelatorioG12 from "./src/Pages/Relatorio/Relatoriog12";
import RelatorioGeral from "./src/Pages/Relatorio/RelatorioGeral";

//Certificados

import Formula from "./src/Pages/Certificado/Formula";
import Intercept from "./src/Pages/Certificado/Intercept";

//Trabalhos

import Trabalho from "./src/Pages/Trabalhos/Fila";
import Trabalhos_2 from "./src/Pages/Trabalhos/Fila/index-2";
import Trabalhos_Aprovados from "./src/Pages/Trabalhos/Aprovados";
import Trabalhos_Aprovados_2 from "./src/Pages/Trabalhos/Aprovados/index-2";
import Trabalhos_Reprovados from "./src/Pages/Trabalhos/Reprovados";
import Trabalhos_Reprovados_2 from "./src/Pages/Trabalhos/Reprovados/index-2";

//Testimunhos
import HomeTestemunho from "./src/Pages/Testemunhos/Home";
import HomeGrupoDepoimento from "./src/Pages/Testemunhos/Formula/Grupos";
import HomeGrupoDepoimentoIntercept from "./src/Pages/Testemunhos/Intercept/Grupos";

import Testemunho_Formula_Grupo5 from "./src/Pages/Testemunhos/Formula/Grupo5";
import Testemunho_Formula_Aprovado_Grupo5 from "./src/Pages/Testemunhos/Formula/Grupo5/aprovado";
import Testemunho_Formula_Guardado_Grupo5 from "./src/Pages/Testemunhos/Formula/Grupo5/guardado";

import Testemunho_Formula_Grupo6 from "./src/Pages/Testemunhos/Formula/Grupo6";
import Testemunho_Formula_Aprovado_Grupo6 from "./src/Pages/Testemunhos/Formula/Grupo6/aprovado";
import Testemunho_Formula_Guardado_Grupo6 from "./src/Pages/Testemunhos/Formula/Grupo6/guardado";

import Testemunho_Intercept_Grupo11 from "./src/Pages/Testemunhos/Intercept/Grupo11";
import Testemunho_Intercept_Aprovado_Grupo11 from "./src/Pages/Testemunhos/Intercept/Grupo11/aprovado";
import Testemunho_Intercept_Guardado_Grupo11 from "./src/Pages/Testemunhos/Intercept/Grupo11/guardado";

import Testemunho_Intercept_Grupo12 from "./src/Pages/Testemunhos/Intercept/Grupo12";
import Testemunho_Intercept_Aprovado_Grupo12 from "./src/Pages/Testemunhos/Intercept/Grupo12/aprovado";
import Testemunho_Intercept_Guardado_Grupo12 from "./src/Pages/Testemunhos/Intercept/Grupo12/guardado";

//Grupo 10
import HomeGrupo10 from "./src/Pages/Grupo10/HomeGrupo10/HomeGrupo10";
import HomeTareaG10 from "./src/Pages/Grupo10/HomeTareaG10/HomeTareas";

import AnalisisFacialG10 from "./src/Pages/Grupo10/Modulo1/AnalisisFacialGrupo10/AnalisisFacialG10";
import AnalisisAprovadosG10 from "./src/Pages/Grupo10/Modulo1/AnalisisFacialGrupo10/AnalisisAprovadosG10";
import AnalisisReprovadosG10 from "./src/Pages/Grupo10/Modulo1/AnalisisFacialGrupo10/AnalisisReprovadosG10";

import CefalometriaG10 from "./src/Pages/Grupo10/Modulo1/CefalometriaGrupo10/CefalometriaG10";
import CefalometriaAprovadosG10 from "./src/Pages/Grupo10/Modulo1/CefalometriaGrupo10/CefalometriaAprovadosG10";
import CefalometriaReprovadosG10 from "./src/Pages/Grupo10/Modulo1/CefalometriaGrupo10/CefalometriaReprovadosG10";

import CefalometriaPDFG10 from "./src/Pages/Grupo10/Modulo1/CefalometriaPDFG10/CefalometriaPDFG10";
import CefalometriaPDFAprovadosG10 from "./src/Pages/Grupo10/Modulo1/CefalometriaPDFG10/CefalometriaPDFAprovadosG10";
import CefalometriaPDFReprovadosG10 from "./src/Pages/Grupo10/Modulo1/CefalometriaPDFG10/CefalometriaPDFReprovadosG10";

import Modulo2G10 from "./src/Pages/Grupo10/Modulo2/Modulo2G10";
import Modulo2AprovadosG10 from "./src/Pages/Grupo10/Modulo2/Modulo2AprovadosG10";
import Modulo2ReprovadosG10 from "./src/Pages/Grupo10/Modulo2/Modulo2ReprovadosG10";

import Modulo3G10 from "./src/Pages/Grupo10/Modulo3/Modulo3G10";
import Modulo3AprovadosG10 from "./src/Pages/Grupo10/Modulo3/Modulo3AprovadosG10";
import Modulo3ReprovadosG10 from "./src/Pages/Grupo10/Modulo3/Modulo3ReprovadosG10";

import Modulo4G10 from "./src/Pages/Grupo10/Modulo4/Modulo4G10";
import Modulo4AprovadosG10 from "./src/Pages/Grupo10/Modulo4/Modulo4AprovadosG10";
import Modulo4ReprovadosG10 from "./src/Pages/Grupo10/Modulo4/Modulo4ReprovadosG10";

import Modulo5G10 from "./src/Pages/Grupo10/Modulo5/Modulo5G10";
import Modulo5AprovadosG10 from "./src/Pages/Grupo10/Modulo5/Modulo5AprovadosG10";
import Modulo5ReprovadosG10 from "./src/Pages/Grupo10/Modulo5/Modulo5ReprovadosG10";

import Modulo6G10 from "./src/Pages/Grupo10/Modulo6/Modulo6G10";
import Modulo6AprovadosG10 from "./src/Pages/Grupo10/Modulo6/Modulo6AprovadosG10";
import Modulo6ReprovadosG10 from "./src/Pages/Grupo10/Modulo6/Modulo6ReprovadosG10";

import Modulo7G10 from "./src/Pages/Grupo10/Modulo7/Modulo7G10";
import Modulo7AprovadosG10 from "./src/Pages/Grupo10/Modulo7/Modulo7AprovadosG10";
import Modulo7ReprovadosG10 from "./src/Pages/Grupo10/Modulo7/Modulo7ReprovadosG10";

import Modulo8G10 from "./src/Pages/Grupo10/Modulo8/Modulo8G10";
import Modulo8AprovadosG10 from "./src/Pages/Grupo10/Modulo8/Modulo8AprovadosG10";
import Modulo8ReprovadosG10 from "./src/Pages/Grupo10/Modulo8/Modulo8ReprovadosG10";

import Modulo9G10 from "./src/Pages/Grupo10/Modulo9/Modulo9G10";
import Modulo9AprovadosG10 from "./src/Pages/Grupo10/Modulo9/Modulo9AprovadosG10";
import Modulo9ReprovadosG10 from "./src/Pages/Grupo10/Modulo9/Modulo9ReprovadosG10";

import Modulo10G10 from "./src/Pages/Grupo10/Modulo10/Modulo10G10";
import Modulo10AprovadosG10 from "./src/Pages/Grupo10/Modulo10/Modulo10AprovadosG10";
import Modulo10ReprovadosG10 from "./src/Pages/Grupo10/Modulo10/Modulo10ReprovadosG10";

//Grupo 11
import HomeGrupo11 from "./src/Pages/Grupo11/HomeGrupo11/HomeGrupo11";
import HomeTareaG11 from "./src/Pages/Grupo11/HomeTareaG11/HomeTareas";

import AnalisisFacialG11 from "./src/Pages/Grupo11/Modulo1/AnalisisFacialGrupo11/AnalisisFacialG11";
import AnalisisAprovadosG11 from "./src/Pages/Grupo11/Modulo1/AnalisisFacialGrupo11/AnalisisAprovadosG11";
import AnalisisReprovadosG11 from "./src/Pages/Grupo11/Modulo1/AnalisisFacialGrupo11/AnalisisReprovadosG11";

import CefalometriaG11 from "./src/Pages/Grupo11/Modulo1/CefalometriaGrupo11/CefalometriaG11";
import CefalometriaAprovadosG11 from "./src/Pages/Grupo11/Modulo1/CefalometriaGrupo11/CefalometriaAprovadosG11";
import CefalometriaReprovadosG11 from "./src/Pages/Grupo11/Modulo1/CefalometriaGrupo11/CefalometriaReprovadosG11";

import CefalometriaPDFG11 from "./src/Pages/Grupo11/Modulo1/CefalometriaPDFG11/CefalometriaPDFG11";
import CefalometriaPDFAprovadosG11 from "./src/Pages/Grupo11/Modulo1/CefalometriaPDFG11/CefalometriaPDFAprovadosG11";
import CefalometriaPDFReprovadosG11 from "./src/Pages/Grupo11/Modulo1/CefalometriaPDFG11/CefalometriaPDFReprovadosG11";

import Modulo2G11 from "./src/Pages/Grupo11/Modulo2/Modulo2G11";
import Modulo2AprovadosG11 from "./src/Pages/Grupo11/Modulo2/Modulo2AprovadosG11";
import Modulo2ReprovadosG11 from "./src/Pages/Grupo11/Modulo2/Modulo2ReprovadosG11";

import Modulo3G11 from "./src/Pages/Grupo11/Modulo3/Modulo3G11";
import Modulo3AprovadosG11 from "./src/Pages/Grupo11/Modulo3/Modulo3AprovadosG11";
import Modulo3ReprovadosG11 from "./src/Pages/Grupo11/Modulo3/Modulo3ReprovadosG11";

import Modulo4G11 from "./src/Pages/Grupo11/Modulo4/Modulo4G11";
import Modulo4AprovadosG11 from "./src/Pages/Grupo11/Modulo4/Modulo4AprovadosG11";
import Modulo4ReprovadosG11 from "./src/Pages/Grupo11/Modulo4/Modulo4ReprovadosG11";

import Modulo5G11 from "./src/Pages/Grupo11/Modulo5/Modulo5G11";
import Modulo5AprovadosG11 from "./src/Pages/Grupo11/Modulo5/Modulo5AprovadosG11";
import Modulo5ReprovadosG11 from "./src/Pages/Grupo11/Modulo5/Modulo5ReprovadosG11";

import Modulo6G11 from "./src/Pages/Grupo11/Modulo6/Modulo6G11";
import Modulo6AprovadosG11 from "./src/Pages/Grupo11/Modulo6/Modulo6AprovadosG11";
import Modulo6ReprovadosG11 from "./src/Pages/Grupo11/Modulo6/Modulo6ReprovadosG11";

import Modulo7G11 from "./src/Pages/Grupo11/Modulo7/Modulo7G11";
import Modulo7AprovadosG11 from "./src/Pages/Grupo11/Modulo7/Modulo7AprovadosG11";
import Modulo7ReprovadosG11 from "./src/Pages/Grupo11/Modulo7/Modulo7ReprovadosG11";

import Modulo8G11 from "./src/Pages/Grupo11/Modulo8/Modulo8G11";
import Modulo8AprovadosG11 from "./src/Pages/Grupo11/Modulo8/Modulo8AprovadosG11";
import Modulo8ReprovadosG11 from "./src/Pages/Grupo11/Modulo8/Modulo8ReprovadosG11";

import Modulo9G11 from "./src/Pages/Grupo11/Modulo9/Modulo9G11";
import Modulo9AprovadosG11 from "./src/Pages/Grupo11/Modulo9/Modulo9AprovadosG11";
import Modulo9ReprovadosG11 from "./src/Pages/Grupo11/Modulo9/Modulo9ReprovadosG11";

import Modulo10G11 from "./src/Pages/Grupo11/Modulo10/Modulo10G11";
import Modulo10AprovadosG11 from "./src/Pages/Grupo11/Modulo10/Modulo10AprovadosG11";
import Modulo10ReprovadosG11 from "./src/Pages/Grupo11/Modulo10/Modulo10ReprovadosG11";

//Grupo 12
import HomeGrupo12 from "./src/Pages/Grupo12/HomeGrupo12/HomeGrupo12";
import HomeTareaG12 from "./src/Pages/Grupo12/HomeTareaG12/HomeTareas";

import AnalisisFacialG12 from "./src/Pages/Grupo12/Modulo1/AnalisisFacialGrupo12/AnalisisFacialG12";
import AnalisisAprovadosG12 from "./src/Pages/Grupo12/Modulo1/AnalisisFacialGrupo12/AnalisisAprovadosG12";
import AnalisisReprovadosG12 from "./src/Pages/Grupo12/Modulo1/AnalisisFacialGrupo12/AnalisisReprovadosG12";

import CefalometriaG12 from "./src/Pages/Grupo12/Modulo1/CefalometriaGrupo12/CefalometriaG12";
import CefalometriaAprovadosG12 from "./src/Pages/Grupo12/Modulo1/CefalometriaGrupo12/CefalometriaAprovadosG12";
import CefalometriaReprovadosG12 from "./src/Pages/Grupo12/Modulo1/CefalometriaGrupo12/CefalometriaReprovadosG12";

import CefalometriaPDFG12 from "./src/Pages/Grupo12/Modulo1/CefalometriaPDFG12/CefalometriaPDFG12";
import CefalometriaPDFAprovadosG12 from "./src/Pages/Grupo12/Modulo1/CefalometriaPDFG12/CefalometriaPDFAprovadosG12";
import CefalometriaPDFReprovadosG12 from "./src/Pages/Grupo12/Modulo1/CefalometriaPDFG12/CefalometriaPDFReprovadosG12";

import Modulo2G12 from "./src/Pages/Grupo12/Modulo2/Modulo2G12";
import Modulo2AprovadosG12 from "./src/Pages/Grupo12/Modulo2/Modulo2AprovadosG12";
import Modulo2ReprovadosG12 from "./src/Pages/Grupo12/Modulo2/Modulo2ReprovadosG12";

import Modulo3G12 from "./src/Pages/Grupo12/Modulo3/Modulo3G12";
import Modulo3AprovadosG12 from "./src/Pages/Grupo12/Modulo3/Modulo3AprovadosG12";
import Modulo3ReprovadosG12 from "./src/Pages/Grupo12/Modulo3/Modulo3ReprovadosG12";

import Modulo4G12 from "./src/Pages/Grupo12/Modulo4/Modulo4G12";
import Modulo4AprovadosG12 from "./src/Pages/Grupo12/Modulo4/Modulo4AprovadosG12";
import Modulo4ReprovadosG12 from "./src/Pages/Grupo12/Modulo4/Modulo4ReprovadosG12";

import Modulo5G12 from "./src/Pages/Grupo12/Modulo5/Modulo5G12";
import Modulo5AprovadosG12 from "./src/Pages/Grupo12/Modulo5/Modulo5AprovadosG12";
import Modulo5ReprovadosG12 from "./src/Pages/Grupo12/Modulo5/Modulo5ReprovadosG12";

import Modulo6G12 from "./src/Pages/Grupo12/Modulo6/Modulo6G12";
import Modulo6AprovadosG12 from "./src/Pages/Grupo12/Modulo6/Modulo6AprovadosG12";
import Modulo6ReprovadosG12 from "./src/Pages/Grupo12/Modulo6/Modulo6ReprovadosG12";

import Modulo7G12 from "./src/Pages/Grupo12/Modulo7/Modulo7G12";
import Modulo7AprovadosG12 from "./src/Pages/Grupo12/Modulo7/Modulo7AprovadosG12";
import Modulo7ReprovadosG12 from "./src/Pages/Grupo12/Modulo7/Modulo7ReprovadosG12";

import Modulo8G12 from "./src/Pages/Grupo12/Modulo8/Modulo8G12";
import Modulo8AprovadosG12 from "./src/Pages/Grupo12/Modulo8/Modulo8AprovadosG12";
import Modulo8ReprovadosG12 from "./src/Pages/Grupo12/Modulo8/Modulo8ReprovadosG12";

import Modulo9G12 from "./src/Pages/Grupo12/Modulo9/Modulo9G12";
import Modulo9AprovadosG12 from "./src/Pages/Grupo12/Modulo9/Modulo9AprovadosG12";
import Modulo9ReprovadosG12 from "./src/Pages/Grupo12/Modulo9/Modulo9ReprovadosG12";

import Modulo10G12 from "./src/Pages/Grupo12/Modulo10/Modulo10G12";
import Modulo10AprovadosG12 from "./src/Pages/Grupo12/Modulo10/Modulo10AprovadosG12";
import Modulo10ReprovadosG12 from "./src/Pages/Grupo12/Modulo10/Modulo10ReprovadosG12";

//Favoritos
import AnalisisFavoritosG12 from "./src/Pages/Grupo12/Modulo1/AnalisisFacialGrupo12/AnalisesFacialFavoritos12";
import CefalometriaFavoritosG12 from "./src/Pages/Grupo12/Modulo1/CefalometriaGrupo12/CefalometriaFavoritosG12";
import Modulo2FavoritosG12 from "./src/Pages/Grupo12/Modulo2/Modulo2FavoritosG12";
import Modulo3FavoritosG12 from "./src/Pages/Grupo12/Modulo3/Modulo3FavoritosG12";
import Modulo4FavoritosG12 from "./src/Pages/Grupo12/Modulo4/Modulo4FavoritosG12";
import Modulo5FavoritosG12 from "./src/Pages/Grupo12/Modulo5/Modulo5FavoritosG12";
import Modulo6FavoritosG12 from "./src/Pages/Grupo12/Modulo6/Modulo6FavoritosG12";
import Modulo7FavoritosG12 from "./src/Pages/Grupo12/Modulo7/Modulo7FavoritosG12";
import Modulo8FavoritosG12 from "./src/Pages/Grupo12/Modulo8/Modulo8FavoritosG12";
import Modulo9FavoritosG12 from "./src/Pages/Grupo12/Modulo9/Modulo9FavoritosG12";
import Modulo10FavoritosG12 from "./src/Pages/Grupo12/Modulo10/Modulo10FavoritosG12";

//Casos Clínicos
import Geral from "./src/Pages/CasosClinicos/Geral";
import Fila from "./src/Pages/CasosClinicos/Fila";
import FilaProfessor from "./src/Pages/CasosClinicos/FilaProfessor";
import MeusCasos from "./src/Pages/CasosClinicos/MeusCasos";
import AguardandoAluno from "./src/Pages/CasosClinicos/AguardandoAluno";
import ConsultaAluno from "./src/Pages/CasosClinicos/ConsultaAluno";
import ProfConsulta from "./src/Pages/CasosClinicos/ProfConsulta";
import CasosConcluidosProf from "./src/Pages/CasosClinicos/CasosConcluidosProf";
import FilaCasosDAO from "./src/Pages/CasosClinicos/FilaCasosDAO";
import FilaProfessorDAO from "./src/Pages/CasosClinicos/FilaProfessorDAO";
import CasosConcluidosDAO from "./src/Pages/CasosClinicos/ConsultaCasosDAO";
import TrocadeHorarioDAO from "./src/Pages/CasosClinicos/TrocadeHorarioDAO";

//Marketplace
import MarketplaceCriacao from "./src/Pages/Marketplace/Criacao";

const Stack = createStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="Login"
          component={Login}
          options={{
            headerShown: false,
            headerStyle: {
              backgroundColor: "",
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trocadesenha"
          component={Trocadesenha}
          options={{
            headerShown: false,
            headerStyle: {
              backgroundColor: "",
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Home"
          component={Home}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="RelatorioG10"
          component={RelatorioG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="RelatorioGeral"
          component={RelatorioGeral}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeGrupo10"
          component={HomeGrupo10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeTareaG10"
          component={HomeTareaG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisFacialG10"
          component={AnalisisFacialG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisAprovadosG10"
          component={AnalisisAprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisReprovadosG10"
          component={AnalisisReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaG10"
          component={CefalometriaG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaAprovadosG10"
          component={CefalometriaAprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaReprovadosG10"
          component={CefalometriaReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />
        <Stack.Screen
          name="CefalometriaPDFG10"
          component={CefalometriaPDFG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaPDFAprovadosG10"
          component={CefalometriaPDFAprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaPDFReprovadosG10"
          component={CefalometriaPDFReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2G10"
          component={Modulo2G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2AprovadosG10"
          component={Modulo2AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2ReprovadosG10"
          component={Modulo2ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3G10"
          component={Modulo3G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3AprovadosG10"
          component={Modulo3AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3ReprovadosG10"
          component={Modulo3ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4G10"
          component={Modulo4G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4AprovadosG10"
          component={Modulo4AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4ReprovadosG10"
          component={Modulo4ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5G10"
          component={Modulo5G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5AprovadosG10"
          component={Modulo5AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5ReprovadosG10"
          component={Modulo5ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6G10"
          component={Modulo6G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6AprovadosG10"
          component={Modulo6AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6ReprovadosG10"
          component={Modulo6ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7G10"
          component={Modulo7G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7AprovadosG10"
          component={Modulo7AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7ReprovadosG10"
          component={Modulo7ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8G10"
          component={Modulo8G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8AprovadosG10"
          component={Modulo8AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8ReprovadosG10"
          component={Modulo8ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9G10"
          component={Modulo9G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9AprovadosG10"
          component={Modulo9AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9ReprovadosG10"
          component={Modulo9ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10G10"
          component={Modulo10G10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10AprovadosG10"
          component={Modulo10AprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10ReprovadosG10"
          component={Modulo10ReprovadosG10}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeGrupo11"
          component={HomeGrupo11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeTareaG11"
          component={HomeTareaG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisFacialG11"
          component={AnalisisFacialG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisAprovadosG11"
          component={AnalisisAprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisReprovadosG11"
          component={AnalisisReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaG11"
          component={CefalometriaG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaAprovadosG11"
          component={CefalometriaAprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaReprovadosG11"
          component={CefalometriaReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />
        <Stack.Screen
          name="CefalometriaPDFG11"
          component={CefalometriaPDFG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaPDFAprovadosG11"
          component={CefalometriaPDFAprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaPDFReprovadosG11"
          component={CefalometriaPDFReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2G11"
          component={Modulo2G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2AprovadosG11"
          component={Modulo2AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2ReprovadosG11"
          component={Modulo2ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3G11"
          component={Modulo3G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3AprovadosG11"
          component={Modulo3AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3ReprovadosG11"
          component={Modulo3ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4G11"
          component={Modulo4G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4AprovadosG11"
          component={Modulo4AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4ReprovadosG11"
          component={Modulo4ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5G11"
          component={Modulo5G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5AprovadosG11"
          component={Modulo5AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5ReprovadosG11"
          component={Modulo5ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6G11"
          component={Modulo6G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6AprovadosG11"
          component={Modulo6AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6ReprovadosG11"
          component={Modulo6ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7G11"
          component={Modulo7G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7AprovadosG11"
          component={Modulo7AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7ReprovadosG11"
          component={Modulo7ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8G11"
          component={Modulo8G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8AprovadosG11"
          component={Modulo8AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8ReprovadosG11"
          component={Modulo8ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9G11"
          component={Modulo9G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9AprovadosG11"
          component={Modulo9AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9ReprovadosG11"
          component={Modulo9ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10G11"
          component={Modulo10G11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10AprovadosG11"
          component={Modulo10AprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10ReprovadosG11"
          component={Modulo10ReprovadosG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeGrupo12"
          component={HomeGrupo12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeTareaG12"
          component={HomeTareaG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisFacialG12"
          component={AnalisisFacialG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisAprovadosG12"
          component={AnalisisAprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisReprovadosG12"
          component={AnalisisReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaG12"
          component={CefalometriaG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaAprovadosG12"
          component={CefalometriaAprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaReprovadosG12"
          component={CefalometriaReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />
        <Stack.Screen
          name="CefalometriaPDFG12"
          component={CefalometriaPDFG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaPDFAprovadosG12"
          component={CefalometriaPDFAprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaPDFReprovadosG12"
          component={CefalometriaPDFReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2G12"
          component={Modulo2G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2AprovadosG12"
          component={Modulo2AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2ReprovadosG12"
          component={Modulo2ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3G12"
          component={Modulo3G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3AprovadosG12"
          component={Modulo3AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3ReprovadosG12"
          component={Modulo3ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4G12"
          component={Modulo4G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4AprovadosG12"
          component={Modulo4AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4ReprovadosG12"
          component={Modulo4ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5G12"
          component={Modulo5G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5AprovadosG12"
          component={Modulo5AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5ReprovadosG12"
          component={Modulo5ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6G12"
          component={Modulo6G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6AprovadosG12"
          component={Modulo6AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6ReprovadosG12"
          component={Modulo6ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7G12"
          component={Modulo7G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7AprovadosG12"
          component={Modulo7AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7ReprovadosG12"
          component={Modulo7ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8G12"
          component={Modulo8G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8AprovadosG12"
          component={Modulo8AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8ReprovadosG12"
          component={Modulo8ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9G12"
          component={Modulo9G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9AprovadosG12"
          component={Modulo9AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9ReprovadosG12"
          component={Modulo9ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10G12"
          component={Modulo10G12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10AprovadosG12"
          component={Modulo10AprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10ReprovadosG12"
          component={Modulo10ReprovadosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Geral"
          component={Geral}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Fila"
          component={Fila}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="FilaProfessor"
          component={FilaProfessor}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="MeusCasos"
          component={MeusCasos}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AguardandoAluno"
          component={AguardandoAluno}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="ConsultaAluno"
          component={ConsultaAluno}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="ProfConsulta"
          component={ProfConsulta}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CasosConcluidosProf"
          component={CasosConcluidosProf}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CasosConcluidosDAO"
          component={CasosConcluidosDAO}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="TrocadeHorarioDAO"
          component={TrocadeHorarioDAO}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="FilaCasosDAO"
          component={FilaCasosDAO}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="FilaProfessorDAO"
          component={FilaProfessorDAO}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Formula"
          component={Formula}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Intercept"
          component={Intercept}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="RelatorioG11"
          component={RelatorioG11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="RelatorioG12"
          component={RelatorioG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trabalho"
          component={Trabalho}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trabalhos_2"
          component={Trabalhos_2}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trabalhos_Aprovados"
          component={Trabalhos_Aprovados}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trabalhos_Aprovados_2"
          component={Trabalhos_Aprovados_2}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trabalhos_Reprovados"
          component={Trabalhos_Reprovados}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Trabalhos_Reprovados_2"
          component={Trabalhos_Reprovados_2}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeGrupoDepoimento"
          component={HomeGrupoDepoimento}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeGrupoDepoimentoIntercept"
          component={HomeGrupoDepoimentoIntercept}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Formula_Grupo5"
          component={Testemunho_Formula_Grupo5}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Formula_Aprovado_Grupo5"
          component={Testemunho_Formula_Aprovado_Grupo5}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Formula_Guardado_Grupo5"
          component={Testemunho_Formula_Guardado_Grupo5}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Formula_Grupo6"
          component={Testemunho_Formula_Grupo6}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Formula_Aprovado_Grupo6"
          component={Testemunho_Formula_Aprovado_Grupo6}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Formula_Guardado_Grupo6"
          component={Testemunho_Formula_Guardado_Grupo6}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Intercept_Grupo11"
          component={Testemunho_Intercept_Grupo11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Intercept_Aprovado_Grupo11"
          component={Testemunho_Intercept_Aprovado_Grupo11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Intercept_Guardado_Grupo11"
          component={Testemunho_Intercept_Guardado_Grupo11}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Intercept_Grupo12"
          component={Testemunho_Intercept_Grupo12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Intercept_Aprovado_Grupo12"
          component={Testemunho_Intercept_Aprovado_Grupo12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Testemunho_Intercept_Guardado_Grupo12"
          component={Testemunho_Intercept_Guardado_Grupo12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="AnalisisFavoritosG12"
          component={AnalisisFavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="CefalometriaFavoritosG12"
          component={CefalometriaFavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo2FavoritosG12"
          component={Modulo2FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo3FavoritosG12"
          component={Modulo3FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo4FavoritosG12"
          component={Modulo4FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo5FavoritosG12"
          component={Modulo5FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo6FavoritosG12"
          component={Modulo6FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo7FavoritosG12"
          component={Modulo7FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo8FavoritosG12"
          component={Modulo8FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo9FavoritosG12"
          component={Modulo9FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="Modulo10FavoritosG12"
          component={Modulo10FavoritosG12}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="HomeTestemunho"
          component={HomeTestemunho}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />

        <Stack.Screen
          name="MarketplaceCriacao"
          component={MarketplaceCriacao}
          options={{
            headerShown: false,
            headerLeft: null,
            headerTitleAlign: "center",
            headerStyle: {
              shadowColor: "transparent",
            },
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
