import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";


export default async function UploadFile(uri, type, mimeType) {

  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });

  var url = Math.floor(Math.random() * 655366545782);

  const storage = getStorage();

  const fileImageRef = ref(
    storage,
    "gs://teste-5e945.appspot.com/Resumos/" + url + type
  );

  const metadata = {
    contentType: mimeType,
  };

  const upload = await uploadBytes(fileImageRef, blob, metadata);

  let UrlDownload = await getDownloadURL(fileImageRef);

  return(UrlDownload)
}
