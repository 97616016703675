import { Platform, StyleSheet } from "react-native";
const styles = StyleSheet.create({
  Title: {
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    marginTop: 30,
  },

  container: {
    flex: 1,
    zoom: 0.65,
    backgroundColor: "#0F0F0F",
  },

  menu: {
    justifyContent: "left",
    alingnItens: "center",
    height: 975,
    width: 300,
    borderTopRightRadius: 70,
    borderBottomRightRadius: 70,
    borderColor: "#29D8FF",
    borderStyle: "solid",
    borderWidth: 2,
    borderLeftColor: "#055669",
  },

  header: {
    height: 115,
    backgroundColor: "#FFFFFF15",
  },

  titleMenu: {
    fontSize: 90,
    fontWeight: "bold",
    color: "#fff",
    marginTop: 300,
    marginBottom: 25,
  },

  buttomMenu: {
    alignItems: "left",
    alignContent: "center",
    position: "absolute",
    marginTop: 30,
    marginLeft: 40,
  },

  MenuModulos: {
    fontSize: 70,
    textAlign: "center",
    color: "#fff",
    fontWeight: "bold",
  },

  MenuModulos2: {
    fontSize: 70,
    textAlign: "center",
    color: "#FF8B00",
    fontWeight: "bold",
  },

  MenuDescription: {
    fontSize: 15,
    textAlign: "center",
    color: "#fff",
  },

  divImg: {
    alingnItens: "center",
    alignContent: "center",
    alignSelf: "center",
  },

  img: {
    width: 900,
    height: 900,
  },

  divImgBack: {
    alingnItens: "center",
    alignContent: "center",
    alignSelf: "center",
    position: "absolute",
    opacity: 0.1,
    marginTop: 250,
  },

  imgBack: {
    width: 1920,
    height: 900,
  },
});

export default styles;
